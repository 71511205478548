import baseFieldHOC from "./BaseFieldHOC";
import CheckboxInput from "./inputs/CheckboxInput/CheckboxInput";
import CustomSelect from "./inputs/CustomSelect";
import DatePickerInput from "./inputs/DatePickerInput";
import DateSelect from "./inputs/DateSelect";
import EditTextInput from "./inputs/EditTextInput/EditTextInput";
import LocationSelect from "./inputs/LocationSelect";
import NumberInput from "./inputs/NumberInput";
import RadioButtonsInput from "./inputs/RadioButtonsInput/RadioButtonsInput";
import SelectInput from "./inputs/SelectInput";
import {StarsInput} from "./inputs/StarsInput";
import SwitchInput from "./inputs/SwitchInput/SwitchInput";
import TextInput from "./inputs/TextInput";
import TextareaInput from "./inputs/TextareaInput";

const CheckboxField = baseFieldHOC(CheckboxInput);
const RadioButtonsField = baseFieldHOC(RadioButtonsInput);
const SelectField = baseFieldHOC(SelectInput);
const TextField = baseFieldHOC(TextInput);
const TextareaField = baseFieldHOC(TextareaInput);
const StarsField = baseFieldHOC(StarsInput);
const EditTextField = baseFieldHOC(EditTextInput);
const SwitchField = baseFieldHOC(SwitchInput);
const NumberField = baseFieldHOC(NumberInput);
const LocationSelectField = baseFieldHOC(LocationSelect);
const DateSelectField = baseFieldHOC(DateSelect);
const DatePickerField = baseFieldHOC(DatePickerInput);
const CustomSelectField = baseFieldHOC(CustomSelect);

export {
  CheckboxField,
  RadioButtonsField,
  SelectField,
  TextField,
  TextareaField,
  StarsField,
  EditTextField,
  SwitchField,
  NumberField,
  LocationSelectField,
  DateSelectField,
  DatePickerField,
  CustomSelectField,
};
