export enum AppRoutes {
  Menu = "menu",
  Orders = "orders",
  Favorites = "favorites",
  Account = "my-account",
  Login = "login",
  AdminDashboard = "admin-dash",
  Invite = "invite",
}

export enum HeaderLinkOptions {
  DropdownOptionLink = "DropdownOptionLink",
  NavigationLink = "NavigationLink",
  FloatingLink = "FloatingLink",
}
