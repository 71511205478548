import React from "react";

import cx from "classnames";

import {useUser} from "@globals/user";

import NomiconIcon from "@components/NomiconIcon/NomiconIcon";
import TransparentButton from "@components/TransparentButton";

import {AppRoutes} from "../interfaces";
import styles from "./HeaderUsername.scss";

type HeaderUsernameProps = {
  showLoginLink?: boolean;
  userClassName: string;
  toggleMenu: () => void;
  isMenuOpen: boolean;
};

export default function HeaderUsername({
  showLoginLink,
  userClassName,
  toggleMenu,
  isMenuOpen,
}: HeaderUsernameProps): JSX.Element {
  const {user, isAuthorized} = useUser();

  return (
    <>
      {isAuthorized ? (
        <TransparentButton
          onClick={toggleMenu}
          className={cx(styles.headerUserName, userClassName)}
          aria-expanded={isMenuOpen}
          aria-label={"Toggle navigation pane"}
        >
          {`HI ${user?.first_name}`}{" "}
          <NomiconIcon
            icon="nomicon-caret-down"
            className={styles.headerUsernameIcon}
          />
        </TransparentButton>
      ) : (
        <>
          {showLoginLink && (
            <a
              className={styles.headerUserName}
              href={`/${AppRoutes.Login}/`}
              target="_self"
              data-testid="header-login-link"
            >
              <span>Log in</span>
            </a>
          )}
        </>
      )}
    </>
  );
}
