import React, {useRef, useState} from "react";

import {
  DaySubsidyInfoData,
  SubsidyPriceVisible,
} from "@apps/daily-menu/interfaces";

import {EventOrigin, recordEvent} from "@globals/config/eventUtils";
import useOnFocusOutside from "@globals/hooks/useOnFocusOutside";
import formatCurrency from "@globals/utils/formatCurrency";

import TransparentButton from "@components/TransparentButton";

import styles from "./DaySubsidyInfo.scss";

type DaySubsidyInfoProps = {
  daySubsidyInfo?: DaySubsidyInfoData;
};

export default function DaySubsidyInfo({
  daySubsidyInfo,
}: DaySubsidyInfoProps): JSX.Element {
  const [showContent, setShowContent] = useState(false);
  const subsidyWrapperRef = useRef<HTMLDivElement>(null);

  useOnFocusOutside(subsidyWrapperRef, () => setShowContent(false));

  const handleButtonClick = () => {
    if (!showContent) {
      recordEvent(EventOrigin.PAGE, "subsidy description", true);
    }
    setShowContent(!showContent);
  };

  if (!daySubsidyInfo?.description) {
    return <></>;
  }
  return (
    <div ref={subsidyWrapperRef} className={styles.subsidyWrapper}>
      <div className={styles.subsidyButtonContainer}>
        <TransparentButton
          onClick={handleButtonClick}
          aria-label={"Toggle subsidy info"}
          className={
            daySubsidyInfo &&
            daySubsidyInfo.remaining_value <= 0 &&
            styles.noRemainingValue
          }
        >
          {daySubsidyInfo && (
            <span>
              {daySubsidyInfo.remaining_value <= 0 ? (
                <span>$</span>
              ) : (
                <span>{formatCurrency(daySubsidyInfo.remaining_value)}</span>
              )}
            </span>
          )}
        </TransparentButton>
      </div>
      {showContent && daySubsidyInfo && (
        <div className={styles.subsidyContent}>
          <div className={styles.subsidyDetail}>
            <div className={styles.subsidyTitle}>Subsidy Information</div>
            {daySubsidyInfo.mealcap_reached && (
              <div>You have reached a subsidy limit for today.</div>
            )}
            <div>{daySubsidyInfo.description}</div>
            {daySubsidyInfo.price_visible ===
              SubsidyPriceVisible.ShowFullPrice && (
              <div>Subsidy discount will be applied at checkout.</div>
            )}
            {daySubsidyInfo.price_visible ===
              SubsidyPriceVisible.ShowSubsidizedPrice && (
              <div>You are seeing the subsidized prices.</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
