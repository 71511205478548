import React, {useMemo} from "react";

import cx from "classnames";

import {useUser} from "@globals/user";
import {
  getLocationForUser,
  groupByPhysicalAddress,
} from "@globals/user/dayPartUtils";
import {LocationType} from "@globals/user/interfaces";

import CustomSelect from "@components/forms/inputs/CustomSelect";

import {
  CustomInputOption,
  CustomSelectProps,
} from "../CustomSelect/CustomSelect";
import styles from "./LocationSelect.scss";

type LocationSelectProps = Omit<
  CustomSelectProps,
  "options" | "label" | "value" | "onChange"
> & {
  label?: string;
  value?: number;
  onChange?: (value: number) => void;
  filter?: (location: LocationType) => boolean;
  shouldChangeUserLocation?: boolean;
  showOneDayPart?: boolean;
};

function getLocationLabel(location: LocationType, isOneDayPart: boolean) {
  if (isOneDayPart) {
    return location.building_and_floor;
  }
  return location.name || location.address;
}

function getLocationOption(
  location: LocationType,
  isOneDayPart: boolean,
  isVisuallyDisabled = false
) {
  const option: CustomInputOption = {
    label: getLocationLabel(location, isOneDayPart),
    value: location.id,
  };
  if (isVisuallyDisabled) {
    option.isVisuallyDisabled = true;
    option.disabledInfo = isOneDayPart
      ? `not available for this meal period`
      : "";
  }
  return option;
}

/**
 * Location Selector component, used across the app.
 * It has two modes:
 * 1. Default: It shows all locations available to the user, not differentiating
 * between day part locations
 * 2. One Day Part mode (if showOneDayPart is true). In this case it will only show
 * locations that have the same day part as the one on currently selected location.
 * It's meant to be used with a separate DayPartSelector component next to it, that
 * will allow the user to switch day parts.
 */
export default function LocationSelect({
  label = "Location",
  placeholder = "Select your location",
  filter = () => true,
  onChange,
  shouldChangeUserLocation,
  className,
  showOneDayPart = false,
  ...otherProps
}: LocationSelectProps): JSX.Element | null {
  const {user, setLocation, isLocationLoading, hasAccessToMultipleDayParts} =
    useUser();

  const handleLocationChange = (value: number) => {
    // prevent unnecessary updates for the same option;
    if (otherProps.value === value) {
      return;
    }
    onChange?.(value);
    if (shouldChangeUserLocation) {
      setLocation?.({id: value});
    }
  };

  const isOneDayPart = showOneDayPart && hasAccessToMultipleDayParts;

  const locations = useMemo(() => {
    const currentDayPart = getLocationForUser(user, otherProps.value)?.day_part;
    const filteredLocations = (user?.locations_list ?? []).filter(filter);
    if (!showOneDayPart) {
      return filteredLocations.map((location) =>
        getLocationOption(location, isOneDayPart)
      );
    }

    // We have location and day part dropdown
    const byPhysicalAddress = groupByPhysicalAddress(filteredLocations);
    const locationOptions = [];
    for (const groupedLocations of Object.values(byPhysicalAddress)) {
      const locationsForCurrentDayPart = groupedLocations.filter(
        (location) => location.day_part === currentDayPart
      );
      if (locationsForCurrentDayPart.length > 0) {
        locationOptions.push(
          ...locationsForCurrentDayPart.map((location) =>
            getLocationOption(location, isOneDayPart)
          )
        );
      } else {
        // We're going to add a location for the other day part, but disabled. Only one! To avoid duplicates
        locationOptions.push(
          getLocationOption(groupedLocations[0], isOneDayPart, true)
        );
      }
    }

    return locationOptions;
  }, [user?.locations_list, filter]);

  if (locations.length <= 1 && !isOneDayPart) {
    // Hide the dropdown if user has one location and one day part
    return null;
  }

  return (
    <CustomSelect
      {...otherProps}
      isLoading={shouldChangeUserLocation && isLocationLoading}
      className={cx(styles.locationSelect, className)}
      label={label}
      placeholder={placeholder}
      options={locations}
      onChange={handleLocationChange}
    />
  );
}
