import React, {MutableRefObject, useCallback, useMemo, useState} from "react";

import cx from "classnames";
import Select, {
  DropdownIndicatorProps,
  OptionProps,
  Props as SelectProps,
  SingleValueProps,
  components,
} from "react-select";

import NomiconIcon from "@components/NomiconIcon";
import useInputId from "@components/forms/hooks/useInputId";
import {InputOption} from "@components/forms/interfaces";

import styles from "./CustomSelect.scss";

export interface CustomSelectProps extends SelectProps {
  className?: string;
  inputClassName?: string;
  options?: InputOption[];
  name: string;
  label?: string;
  customOption?: (props: OptionProps<InputOption>) => JSX.Element;
  customSingleValue?: (props: SingleValueProps<InputOption>) => JSX.Element;
  onChange?: (value: string | number) => void;
  withBorder?: boolean;
  mobileViewIcon?: string;
  isTaller?: boolean;
  selectRef?: MutableRefObject<{clearValue: () => void} | null>;
}

export interface CustomInputOption extends InputOption {
  isVisuallyDisabled?: boolean; // Visually disabled but still clickable
  disabledInfo?: string; // String that will be displayed next to disabled option
}

const DefaultOption = (props: OptionProps<CustomInputOption>) => (
  <div
    {...props.innerProps}
    className={cx(props.className, styles.defaultOption, {
      [styles.selected]: props.isSelected,
      [styles.disabled]: props.data.isVisuallyDisabled || props.isDisabled,
      [styles.focused]: props.isFocused,
    })}
  >
    {props.label}
    {(props.isDisabled || props.data.isVisuallyDisabled) &&
      props.data.disabledInfo && <span> ({props.data.disabledInfo})</span>}
  </div>
);

const getMenuPortal = ({
  props,
  mobileViewIcon,
  isTaller,
}: {
  props: React.ComponentProps<typeof components.MenuPortal>;
  mobileViewIcon?: string;
  isTaller?: boolean;
}) => (
  <components.MenuPortal
    {...props}
    className={cx(
      props.className,
      styles.menuContainer,
      mobileViewIcon && styles.mobileViewIconMenu,
      isTaller && styles.taller
    )}
  >
    {props.children}
  </components.MenuPortal>
);

type CustomIndicatorProps = {
  mobileViewIcon?: string;
  indicatorProps: DropdownIndicatorProps;
};

const getDropdownIndicator = ({
  mobileViewIcon,
  indicatorProps,
}: CustomIndicatorProps): JSX.Element => {
  return (
    <components.DropdownIndicator {...indicatorProps}>
      {mobileViewIcon && (
        <NomiconIcon className={styles.customIcon} icon={mobileViewIcon} />
      )}
      <NomiconIcon className={styles.caretIcon} icon={"nomicon-caret-down"} />
    </components.DropdownIndicator>
  );
};

export default function CustomSelect({
  className,
  inputClassName,
  name,
  options,
  customOption,
  customSingleValue,
  label,
  onChange,
  value,
  defaultValue,
  withBorder,
  mobileViewIcon,
  isTaller,
  isSearchable = false,
  selectRef,
  ...otherProps
}: CustomSelectProps): JSX.Element {
  const handleChange = useCallback(
    (selectedOption: CustomInputOption) => {
      if (typeof onChange === "function") {
        onChange(selectedOption?.value);
      }
    },
    [onChange]
  );

  const selectedOption = useMemo(() => {
    return options?.find(function (option) {
      return option.value === value;
    });
  }, [value, options]);

  const defaultOption = options?.find(function (option) {
    return option.value === defaultValue;
  });

  const [isFocused, setIsFocused] = useState(false);

  const inputId = useInputId(name);

  const getIndicatorWithProps = useCallback(
    (props: DropdownIndicatorProps) => {
      return getDropdownIndicator({
        mobileViewIcon,
        indicatorProps: props,
      });
    },
    [mobileViewIcon]
  );

  const getMenuPortalWithProps = useCallback(
    (props: any) => {
      return getMenuPortal({props, mobileViewIcon, isTaller});
    },
    [mobileViewIcon]
  );

  return (
    <div
      className={cx(
        styles.container,
        className,
        mobileViewIcon && styles.customMobileView,
        {
          [styles.noLabel]: !label,
          [styles.withBorder]: withBorder,
          [styles.isFocused]: isFocused,
        }
      )}
    >
      {label && <label htmlFor={inputId}>{label}</label>}
      <Select
        name={name}
        ref={(ref) => {
          if (selectRef) {
            selectRef.current = ref;
          }
        }}
        components={{
          Option: customOption ?? DefaultOption,
          SingleValue: customSingleValue ?? components.SingleValue,
          IndicatorSeparator: null,
          MenuPortal: getMenuPortalWithProps,
          DropdownIndicator: getIndicatorWithProps,
        }}
        options={options}
        className={cx(styles.select, inputClassName)}
        classNamePrefix="custom-select"
        isSearchable={isSearchable}
        inputId={inputId}
        {...otherProps}
        value={selectedOption}
        defaultValue={defaultOption}
        onChange={handleChange}
        menuPortalTarget={document.body}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
}
