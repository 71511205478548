import React, {useCallback} from "react";

import classNames from "classnames";

import useInputId from "@components/forms/hooks/useInputId";
import {InputOption} from "@components/forms/interfaces";
import TextInputLayout from "@components/forms/layouts/TextInputLayout";

import styles from "./SelectInput.scss";

export type SelectInputProps = {
  name: string;
  onChange?: (value: string | number) => void;
  inputClassName?: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | number;
  label?: string;
  error?: string;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  options?: InputOption[];
  withLayout?: boolean;
};

export default function SelectInput({
  inputClassName = "input-custom",
  value,
  name,
  onChange,
  options = [],
  placeholder,
  withLayout = true,
  ...props
}: SelectInputProps): JSX.Element {
  const inputId = useInputId(name);
  const testId = `select-input-${name}`;
  const placeholderValue = placeholder ? "default" : undefined;
  const handleChange = useCallback(
    (e: any) => {
      if (typeof onChange === "function") {
        onChange(
          options[e.target.selectedOptions[0].dataset.optionIndex].value
        );
      }
    },
    [onChange, options]
  );
  const SelectNode = (
    <select
      {...props}
      value={value || placeholderValue}
      className={classNames(styles.selectInput, inputClassName)}
      id={inputId}
      data-testid={testId}
      onChange={handleChange}
    >
      {placeholder && (
        <option value="default" disabled>
          {placeholder}
        </option>
      )}
      {options.map((option, index) => (
        <option
          key={option.value}
          value={option.value}
          data-option-index={index}
        >
          {option.label}
        </option>
      ))}
    </select>
  );

  if (withLayout) {
    return (
      <TextInputLayout
        disabled={props.disabled}
        label={props.label}
        error={props.error}
        required={props.required}
        labelFor={inputId}
        withArrow={true}
      >
        {SelectNode}
      </TextInputLayout>
    );
  }

  return SelectNode;
}
