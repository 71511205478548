import React, {Component, ReactNode} from "react";

import {ErrorBoundary as SentryErrorBoundary} from "@sentry/react";

import SupportEmail from "@components/SupportEmail";
import Footer from "@components/layout/Footer";
import Header from "@components/layout/Header";

import styles from "./ErrorBoundary.scss";

type ErrorBoundaryProps = {
  children: ReactNode;
};

const Fallback = () => (
  <>
    <Header showLoginLink={true} showMealPrograms={false} />
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.title}>Sorry - we encountered an error!</h1>
        <p className={styles.errorText}>
          The engineering team has been notified and will address this issue
          ASAP.
        </p>
        <p className={styles.focusText}>
          Please contact <SupportEmail /> for other questions.
        </p>
      </div>
    </div>
    <Footer />
  </>
);

export default class ErrorBoundary extends Component<ErrorBoundaryProps> {
  render(): ReactNode {
    return (
      <SentryErrorBoundary fallback={Fallback}>
        {this.props.children}
      </SentryErrorBoundary>
    );
  }
}
