import React from "react";

import cx from "classnames";
import {Link, LinkProps} from "react-router-dom";

import styles from "./Button.scss";
import {ButtonAccent} from "./interfaces";

type ButtonAttributes = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = (ButtonAttributes | AnchorAttributes | LinkProps) & {
  withBorder?: boolean;
  withThickBorder?: boolean;
  accent?: ButtonAccent;
};

function isLink(
  props: ButtonAttributes | AnchorAttributes | LinkProps
): props is AnchorAttributes {
  return (props as AnchorAttributes).href !== undefined;
}

function isRouterLink(
  props: ButtonAttributes | AnchorAttributes | LinkProps
): props is LinkProps {
  return (props as LinkProps).to !== undefined;
}

export default function Button({
  children,
  className,
  withBorder,
  withThickBorder,
  accent = ButtonAccent.AccentDefault,
  ...props
}: ButtonProps): JSX.Element {
  const isBordered = withBorder || withThickBorder;
  const borderedClassName = cx({
    [styles.ecCapBtn]: !isBordered,
    [styles.ecCapBorderBtn]: isBordered,
  });

  className = cx(
    borderedClassName,
    withThickBorder && [styles.withThickBorder],
    className,
    accent && styles[accent]
  );

  if (isLink(props)) {
    return (
      <a className={className} {...(props as AnchorAttributes)}>
        {children}
      </a>
    );
  }

  if (isRouterLink(props)) {
    return (
      <Link className={className} {...(props as LinkProps)}>
        {children}
      </Link>
    );
  }

  return (
    <button className={className} {...(props as ButtonAttributes)}>
      {children}
    </button>
  );
}
