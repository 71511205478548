import React from "react";

import cx from "classnames";

import footerLogoImageUrl from "@globals/img/logo-white-text.svg";

import SupportEmail from "@components/SupportEmail";
import TransparentButton from "@components/TransparentButton";

import styles from "./Footer.scss";

export default function Footer(): JSX.Element {
  const PRIVACY_REQUEST_URL = `\
https://privacyportal-eu-cdn.onetrust.com/dsarwebform/8394ad8c-2b46-4837-8771-cbc69779\
a644/31bea1f4-92c3-440b-be0e-468af4f4b1f3.html?Source=EATClub`;
  const CODE_OF_CONDUCT_URL = `\
https://www.compass-usa.com/wp-content/uploads/2023/10/Code-of-Business-Conduct-English.pdf`;
  const currentYear: number = new Date().getFullYear();
  const scrollToTop = (e: React.MouseEvent<HTMLButtonElement>) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <footer className={styles.globalFooterWrapper}>
      <div className={styles.globalFooter}>
        <div className={styles.footerLinksWrapper}>
          <div className={styles.footerLinks}>
            <a href="/how-it-works">How It Works</a>
            <a href="/about">About</a>
            <a href="/what-youll-love">What You'll Love</a>
            <a href="/careers">Careers</a>
            <a href="/the-eat-club-difference">The EAT Club Difference</a>
            <a href="/what-youll-love#faq">FAQ</a>
            <span className={styles.backToTop}>
              <TransparentButton onClick={scrollToTop} aria-label="Back to top">
                Back to Top
              </TransparentButton>
            </span>
          </div>
        </div>
        <div className={styles.footerLogo}>
          <img src={footerLogoImageUrl} width="110" alt="EAT Club" />
        </div>
        <div className={styles.footerContact}>
          <p className={styles.contactPrompt}>Questions?</p>
          <SupportEmail
            className={styles.contactButton}
            linkText="Contact us"
            isLink
          />
        </div>
        <div
          className={cx(styles.footerAddress, styles.footerSectionTopBorder)}
        >
          <p>
            2400 Yorkmont Rd
            <br />
            Charlotte, NC 28217
            <br />
            ©EAT Club {currentYear}
          </p>
        </div>
        <div
          className={cx(styles.footerCopyright, styles.footerSectionTopBorder)}
        >
          <p>
            <a href="/privacy">Privacy Policy</a> |{" "}
            <a href={PRIVACY_REQUEST_URL}>Privacy Request</a>
            <br />
            <a href="/terms">Terms of Service</a> |{" "}
            <a href={CODE_OF_CONDUCT_URL}>Code of Business Conduct</a>
          </p>
        </div>
      </div>
    </footer>
  );
}
