import React, {useRef, useState} from "react";

import cx from "classnames";

import useOnFocusOutside from "@globals/hooks/useOnFocusOutside";
import {useUser} from "@globals/user";
import {Product} from "@globals/user/interfaces";

import {AccessRightFlags, CheckAccess} from "@components/CheckAccess";
import TransparentButton from "@components/TransparentButton";

import HeaderLink from "../HeaderLink/HeaderLink";
import HeaderUsername from "../HeaderUsername/HeaderUsername";
import {AppRoutes, HeaderLinkOptions} from "../interfaces";
import styles from "./HeaderDropdown.scss";

type HeaderDropdownProps = {
  showLoginLink?: boolean;
  adminDashUrl: string;
};

export default function HeaderDropdown({
  showLoginLink,
  adminDashUrl,
}: HeaderDropdownProps): JSX.Element {
  const [showNavDropdown, setShowNavDropdown] = useState<boolean>(false);
  const {isLunchVisible, hasPPG, logout, isAuthorized} = useUser();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnFocusOutside(dropdownRef, () => setShowNavDropdown(false));

  const toggleMenu = () => {
    setShowNavDropdown(!showNavDropdown);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      ref={dropdownRef}
      className={cx(
        styles.headerDropdownSection,
        isAuthorized && styles.hoverable,
        showNavDropdown && styles.dropdownOpen
      )}
    >
      <HeaderUsername
        showLoginLink={showLoginLink}
        toggleMenu={toggleMenu}
        isMenuOpen={showNavDropdown}
        userClassName={styles.userName}
      />
      {/* this is toggle button for mobile */}
      <TransparentButton
        className={styles.hamburger}
        aria-label="Toggle mobile navigation pane"
        onClick={toggleMenu}
      >
        <span
          className={cx(styles.hamburgerBars, showNavDropdown && styles.open)}
        />
      </TransparentButton>
      <div
        className={cx(
          styles.dropdownOptions,
          showNavDropdown && styles.showDropdown
        )}
        role="menu"
      >
        {/* this will be shown for mobile and desktop authorized users */}
        <CheckAccess flag={AccessRightFlags.Authorized}>
          <>
            {isLunchVisible() && (
              <HeaderLink
                text={"Daily Menu"}
                url={`/${AppRoutes.Menu}/`}
                typeOfLink={HeaderLinkOptions.FloatingLink}
              />
            )}
            <CheckAccess flag={AccessRightFlags.IsMasterAccountHolder}>
              <HeaderLink
                url={adminDashUrl}
                text={"Admin Dashboard"}
                typeOfLink={HeaderLinkOptions.FloatingLink}
              />
            </CheckAccess>
            <HeaderLink
              url={`/${AppRoutes.Orders}/`}
              text={"My Orders"}
              typeOfLink={HeaderLinkOptions.DropdownOptionLink}
            />
            {hasPPG(Product.Lunch) && (
              <HeaderLink
                url={`/${AppRoutes.Favorites}/`}
                text={"My Favorites"}
                typeOfLink={HeaderLinkOptions.DropdownOptionLink}
              />
            )}
            <HeaderLink
              url={`/${AppRoutes.Account}/`}
              text={"My Account"}
              typeOfLink={HeaderLinkOptions.DropdownOptionLink}
            />
            <HeaderLink
              url={"#"}
              text={"Logout"}
              typeOfLink={HeaderLinkOptions.DropdownOptionLink}
              onClickHandler={() => {
                logout({redirect: true});
              }}
            />
          </>
        </CheckAccess>
        {/* this will be shown only for mobile non-logined users */}
        <CheckAccess flag={AccessRightFlags.Unauthorized}>
          <HeaderLink
            url={"/"}
            text={"Meal Programs"}
            typeOfLink={HeaderLinkOptions.FloatingLink}
          />
          {showLoginLink && (
            <HeaderLink
              url={`/${AppRoutes.Login}/`}
              text={"Log in"}
              typeOfLink={HeaderLinkOptions.FloatingLink}
            />
          )}
        </CheckAccess>
      </div>
    </div>
  );
}
