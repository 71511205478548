import React from "react";

import {FieldValidator} from "final-form";
import {Field} from "react-final-form";

import BaseFieldLayout, {BaseFieldLayoutProps} from "./layouts/BaseFieldLayout";

// Workaround so that react-final-form never sets the value to null
// https://github.com/final-form/react-final-form/issues/130
function identity(value: unknown) {
  return value;
}

type FieldAdditionalProps = {
  name: string;
  validate?: FieldValidator<any>;
};

/**
 * Use this HOC to connect any field from /inputs directory to the final-form library
 * and to use our basic form layout with logic - BaseFieldLayout
 * @param Component - The component to be wrapped.
 * @returns Wrapped component.
 **/
export default function baseFieldHOC<T>(
  Component: React.ComponentType<T>
): (props: T & FieldAdditionalProps & BaseFieldLayoutProps) => JSX.Element {
  const WrappedField = (
    props: T & FieldAdditionalProps & BaseFieldLayoutProps
  ) => {
    return (
      <Field
        component={BaseFieldLayout}
        inputComponent={Component}
        parse={identity}
        {...props}
      />
    );
  };
  return WrappedField;
}
