import React, {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";

import * as SiteConfigService from "./SiteConfigService";
import {
  SiteConfigContextInterface,
  SiteConfigMessage,
  Sites,
} from "./interfaces";

export const SiteConfigContext = createContext<SiteConfigContextInterface>(
  {} as SiteConfigContextInterface
);

export function SiteConfigProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [cache, setCache] = useState<{[key: string]: string}>({});

  const updateCache = useCallback(
    (key: string, value: any) => {
      setCache({
        ...cache,
        [key]: value,
      });
    },
    [cache]
  );

  const memoizedConfig = useMemo(
    () => ({
      getSupportEmail: () => {
        if (!("supportEmailAddress" in cache)) {
          SiteConfigService.fetchSiteConfigMessage({
            key: SiteConfigMessage.SupportEmailAddress,
          }).then((value) => updateCache("supportEmailAddress", value));
        }

        return cache.supportEmailAddress || "help@eatclub.com";
      },

      isMainEatclubSite: () => {
        if (!("currentSite" in cache)) {
          void SiteConfigService.fetchCurrentSite().then((value) =>
            updateCache("currentSite", value)
          );
        }

        return cache.currentSite === Sites.MainEatclubSite;
      },

      getAllergenOverride: (tenantSlug: string) => {
        const cacheKey = `${tenantSlug}::allergenOverride`;
        if (!(cacheKey in cache)) {
          return SiteConfigService.fetchSiteConfigMessage({
            key: SiteConfigMessage.AllergenOverride,
            tenantSlug,
          }).then((value) => {
            updateCache(cacheKey, value);
            return value;
          });
        }

        return cache[cacheKey];
      },

      getDishReviewTitle: () => {
        if (!("dishReviewTitle" in cache)) {
          SiteConfigService.fetchSiteConfigMessage({
            key: SiteConfigMessage.DishReviewTitle,
          }).then((value) => updateCache("dishReviewTitle", value));
        }

        return cache.dishReviewTitle || "How did you like it?";
      },
    }),
    [cache]
  );

  return (
    <SiteConfigContext.Provider value={memoizedConfig}>
      {children}
    </SiteConfigContext.Provider>
  );
}
