const TEST_ENVIRONMENTS = [
  "wwwtest.eatclub.com",
  "wwwstaging.eatclub.com",
  "wwwdemo.eatclub.com",
  "continentaltest.eatclub.com",
  "continentalstaging.eatclub.com",
  "continentaldemo.eatclub.com",
];

function isProduction(): boolean {
  const hostName = window.location.hostname;
  if (TEST_ENVIRONMENTS.includes(hostName)) {
    return false;
  }
  return hostName.includes(`eatclub.com`);
}

export {isProduction};
