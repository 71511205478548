import React, {Children, cloneElement} from "react";

import TooltipView from "./TooltipView";
import {TooltipProps, TooltipVariant} from "./interfaces";
import useTooltip from "./useTooltip";

export default function Tooltip({
  children,
  content,
  className,
  placement = "top",
  variant = TooltipVariant.Default,
}: TooltipProps): JSX.Element {
  const {
    isTooltipShown,
    arrowRef,
    arrowX,
    arrowY,
    useFloatingData,
    showTooltip,
    hideTooltip,
    staticSide,
  } = useTooltip(placement);

  const child = cloneElement(Children.only(children), {
    key: "tooltip-control",
    ref: useFloatingData.refs.setReference,
    onMouseEnter: showTooltip,
    onMouseLeave: hideTooltip,
    onFocus: showTooltip,
    onBlur: hideTooltip,
  });

  return (
    <>
      {child}
      {isTooltipShown && (
        <TooltipView
          tooltipKey={`${useFloatingData.placement}-tooltip`}
          className={className}
          content={content}
          arrowRef={arrowRef}
          arrowX={arrowX}
          arrowY={arrowY}
          staticSide={staticSide}
          variant={variant}
          useFloatingData={useFloatingData}
        />
      )}
    </>
  );
}
