import {eatClubClient} from "@globals/config/apiClient";

import {Sites} from "./interfaces";

export function fetchSiteConfigMessage({
  key,
  tenantSlug,
}: {
  key: string;
  tenantSlug?: string;
}): Promise<string> {
  const url = `/environment-messages/${
    tenantSlug ? `${tenantSlug}/` : ""
  }?names=${key}`;
  return eatClubClient.get(url).then((response) => response.data[key]);
}

export function fetchCurrentSite(): Promise<string> {
  const host = window.location.host;
  if (!Object.values(Sites).includes(host as Sites)) {
    return eatClubClient
      .get("/api/v3/environment/")
      .then((response) => response.data.environment);
  }
  return Promise.resolve(host);
}
