import * as Sentry from "@sentry/react";
import {omit} from "lodash";

import {UserType} from "@globals/user/interfaces";

export function recordErrorToSentry(message: string): void {
  Sentry.captureMessage(message);
}

export function recordBreadcrumbToSentry({
  message,
  category,
  data,
}: {
  message: string;
  category: string;
  data?: {value: any};
}): void {
  Sentry.addBreadcrumb({
    message,
    category,
    data,
  });
}

export function configureSentryUser(userData: UserType): void {
  const fieldsToRemove = [
    "email",
    "password",
    "first_name",
    "last_name",
    "auth_token",
    "phone_number",
  ];
  const sentryUser = omit(userData, fieldsToRemove);
  Sentry.configureScope((scope) =>
    scope.setUser(sentryUser as unknown as Sentry.User)
  );
}
