import {setIn} from "final-form";
import * as yup from "yup";

import {ObjectWithAnyProps} from "@globals/types/utilityTypes";

// To be passed to React Final Form
// Runs Yup validation schema and returns array of errors
export const validateFormValues =
  (schema: ObjectWithAnyProps) =>
  async (
    values: ObjectWithAnyProps
  ): Promise<Record<string, never> | undefined> => {
    if (typeof schema === "function") {
      schema = schema();
    }
    try {
      await schema.validate(values, {abortEarly: false});
    } catch (err) {
      let errors;
      if (err instanceof yup.ValidationError) {
        errors = err.inner.reduce((formError, innerError) => {
          return setIn(formError, innerError.path || "", innerError.message);
        }, {});
      }
      return errors;
    }
  };
