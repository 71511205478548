import React, {ReactNode, createContext, useMemo, useState} from "react";

import isFunction from "lodash/isFunction";

import {AppConfigContextData, AppConfigData, NewConfig} from "./interfaces";

/**
 * Wrap your app with AppConfigProvider to have a shared 'store' between components
 * useAppConfig provides acces to the context from any child component
 * Also there is a setter function updateConfig to update data inside context
 * for now config has only openedDropDownId but it can be expanded
 * to store menu filters and other shared data. Feel free to add new entries
 */

type AppConfigProviderProps = {
  children: ReactNode;
};

const defaultConfig: AppConfigData = {
  openedDropDownId: null,
  sentReminders: {},
  isNewExtrasOrder: false,
  selectedMarkerRoutingId: undefined,
};

export const AppConfigContext = createContext<AppConfigContextData>(
  {} as AppConfigContextData
);

export function AppConfigProvider({
  children,
}: AppConfigProviderProps): JSX.Element {
  const [config, setConfig] = useState<AppConfigData>(defaultConfig);

  const updateConfig = (newConfig: NewConfig) => {
    setConfig((prevConfig) => ({
      ...config,
      ...(isFunction(newConfig) ? newConfig(prevConfig) : newConfig),
    }));
  };

  const memoizedConfig = useMemo(
    () => ({
      appConfig: config,
      setAppConfig: updateConfig,
    }),
    [config]
  );

  return (
    <AppConfigContext.Provider value={memoizedConfig}>
      {children}
    </AppConfigContext.Provider>
  );
}
