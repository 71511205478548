import React, {useEffect, useRef, useState} from "react";

import {EventOrigin, recordEvent} from "@globals/config/eventUtils";
import useOnFocusOutside from "@globals/hooks/useOnFocusOutside";
import {useUser} from "@globals/user";

import HideText from "@components/HideText";
import NomiconIcon from "@components/NomiconIcon/NomiconIcon";
import TransparentButton from "@components/TransparentButton";

import NotificationSection from "./NotificationSection/NotificationSection";
import styles from "./Notifications.scss";
import * as NotificationsService from "./NotificationsService";
import {NotificationItem, NotificationType} from "./interfaces";

const Notifications = (): JSX.Element => {
  const [showNotificationsList, setShowNotificationsList] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<Array<NotificationItem>>(
    []
  );
  const [notificationsCount, setNotificationsCount] = useState<number>();
  const [hasTodayNotifications, setHasTodayNotifications] = useState<boolean>();
  const [hasEarlierNotifications, setHasEarlierNotifications] =
    useState<boolean>();

  const {user} = useUser();

  const refNotificationContainer = useRef<HTMLInputElement>(null);

  useOnFocusOutside(refNotificationContainer, () =>
    setShowNotificationsList(false)
  );

  const handleHeadings: (
    updatedNotifications: Array<NotificationItem>
  ) => void = (updatedNotifications: Array<NotificationItem>) => {
    const isTodayNotifications: boolean = updatedNotifications.some(
      (value) => value.isToday
    );
    const isEarlierNotifications: boolean = updatedNotifications.some(
      (value) => !value.isToday
    );

    setHasTodayNotifications(isTodayNotifications);
    setHasEarlierNotifications(isEarlierNotifications);
  };

  useEffect(() => {
    void NotificationsService.getNotifications().then(
      (result: Array<Array<NotificationItem>>) => {
        const flatNotifications: Array<NotificationItem> = result.flat();

        if (flatNotifications.length) {
          handleHeadings(flatNotifications);
        }

        const unreadNotifications: Array<NotificationItem> =
          flatNotifications.filter(
            (notification: NotificationItem) => notification.unread
          );

        if (unreadNotifications.length) {
          setNotificationsCount(unreadNotifications.length);
        }

        setNotifications(flatNotifications);
      }
    );
  }, []);

  const toggleNotifications: () => void = () => {
    if (!showNotificationsList) {
      recordEvent(EventOrigin.PAGE, "clicked notification bell", user?.id);
    }
    setShowNotificationsList(!showNotificationsList);

    if (notificationsCount) {
      setNotificationsCount(0);
    }
  };

  const dismissNotification: (id: number, type: NotificationType) => void = (
    id: number,
    type: NotificationType
  ) => {
    const eventAction: string =
      type === NotificationType.Django
        ? "dismiss notification"
        : "dismiss lunch notification";

    recordEvent(EventOrigin.PAGE, eventAction, id);

    (type === NotificationType.Django
      ? NotificationsService.dismissDjangoNotification(id)
      : NotificationsService.dismissLunchNotification(id)
    ).then(() => {
      const filteredNotifications: Array<NotificationItem> =
        notifications.filter((notification) => notification.id !== id);
      handleHeadings(filteredNotifications);
      setNotifications(filteredNotifications);

      if (!filteredNotifications.length) {
        toggleNotifications();
      }
    });
  };

  return (
    <div
      ref={refNotificationContainer}
      className={styles.notificationContainer}
    >
      {!!notificationsCount && (
        <span
          className={styles.notificationNumberCircle}
          id="notification-count"
        >
          {notificationsCount}
        </span>
      )}
      <TransparentButton
        className={styles.notificationBell}
        onClick={toggleNotifications}
      >
        <HideText>Toggle notifications</HideText>
        <NomiconIcon icon="nomicon-remind" />
      </TransparentButton>
      {showNotificationsList && (
        <div className={styles.notificationListContainer}>
          {!!notifications.length && (
            <div className={styles.notificationsContainer}>
              {hasTodayNotifications && (
                <NotificationSection
                  title={"Today"}
                  dismissNotification={dismissNotification}
                  notifications={notifications.filter(
                    (notification: NotificationItem) => notification.isToday
                  )}
                />
              )}

              {hasEarlierNotifications && (
                <NotificationSection
                  title={"Earlier"}
                  dismissNotification={dismissNotification}
                  notifications={notifications.filter(
                    (notification: NotificationItem) => !notification.isToday
                  )}
                />
              )}
            </div>
          )}

          {notifications.length === 0 && (
            <div className={styles.emptyNotifications}>No notifications</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
