import {UserType} from "@globals/user/interfaces";

import {AccessRightFlags} from "../interfaces";

export default function collectUserFlags(user?: UserType): AccessRightFlags[] {
  const isAuthorized = Boolean(user);
  const flags: AccessRightFlags[] = [];

  flags.push(
    isAuthorized ? AccessRightFlags.Authorized : AccessRightFlags.Unauthorized
  );

  if (user?.admin_products?.length) {
    flags.push(AccessRightFlags.IsMasterAccountHolder);

    if (user?.is_limited_master_account_holder === false) {
      flags.push(AccessRightFlags.IsFullAdmin);
    }
  }

  return flags;
}
