import React, {ReactNode, useMemo} from "react";

import {UseFloatingReturn} from "@floating-ui/react-dom";
import classNames from "classnames";

import styles from "./index.scss";
import {TooltipVariant} from "./interfaces";

type TooltipViewProps = {
  content: ReactNode;
  className?: string;
  variant: TooltipVariant;
  tooltipKey: string;
  arrowRef: React.MutableRefObject<null>;
  arrowX?: number;
  arrowY?: number;
  staticSide: string;
  useFloatingData: UseFloatingReturn<HTMLElement>;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

// This is just a view, shared between Tooltip and Popover components.
export default function TooltipView({
  content,
  arrowRef,
  arrowX,
  arrowY,
  staticSide,
  variant,
  tooltipKey,
  useFloatingData,
  onMouseOver,
  onMouseOut,
  className,
}: TooltipViewProps): JSX.Element {
  const tooltipClassName = useMemo(() => {
    return classNames(
      styles.tooltip,
      styles[variant],
      className,
      styles[useFloatingData.placement]
    );
  }, [useFloatingData.placement]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      key={tooltipKey}
      className={tooltipClassName}
      ref={useFloatingData.refs.setFloating}
      style={{
        position: useFloatingData.strategy,
        top: useFloatingData.y ?? 0,
        left: useFloatingData.x ?? 0,
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {content}
      <div
        className={styles.arrow}
        ref={arrowRef}
        style={{
          left: arrowX === null ? "" : `${arrowX}px`,
          top: arrowY === null ? "" : `${arrowY}px`,
          right: "",
          bottom: "",
          [staticSide]: variant === TooltipVariant.Default ? "-4px" : "-7px",
        }}
      />
    </div>
  );
}
