import React, {ReactNode, useMemo} from "react";

import {ACCESS_REDIRECT_URL} from "@globals/constants/localStorageKeys";
import {useUser} from "@globals/user";

import {AccessRightFlags} from "./interfaces";
import collectUserFlags from "./utils/collectUserFlags";

type CheckAccessProps = {
  flag: AccessRightFlags;
  children: ReactNode;
  redirectTo?: string;
  fallback?: ReactNode;
  saveUrl?: boolean;
};

export default function CheckAccess({
  flag,
  children,
  redirectTo,
  saveUrl = true,
  fallback = null,
}: CheckAccessProps): JSX.Element {
  const {user} = useUser();

  const userFlags: AccessRightFlags[] = useMemo(
    () => collectUserFlags(user),
    [user]
  );

  const hasAccess: boolean = userFlags.includes(flag);

  if (hasAccess) {
    return <>{children}</>;
  }

  if (redirectTo) {
    localStorage.removeItem(ACCESS_REDIRECT_URL);
    if (saveUrl) {
      localStorage.setItem(ACCESS_REDIRECT_URL, window.location.pathname);
    }

    window.location.assign(redirectTo);
  }

  return <>{fallback}</>;
}
