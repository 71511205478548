import React, {forwardRef} from "react";

import cx from "classnames";

import styles from "./TransparentButton.scss";

interface TransparentButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  "aria-label"?: string;
  children: React.ReactNode;
  className?: string;
}

function TransparentButton(
  {children, onClick, className, ...otherProps}: TransparentButtonProps,
  ref: React.RefObject<HTMLButtonElement>
): JSX.Element {
  return (
    <button
      type="button"
      title={otherProps["aria-label"]}
      onClick={onClick}
      ref={ref}
      className={cx(styles.transparentBtn, className)}
      {...otherProps}
    >
      {children}
    </button>
  );
}

export default forwardRef(TransparentButton);
