import {useRef, useState} from "react";

import {
  Placement,
  UseFloatingReturn,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react-dom";

type UseTooltipData = {
  showTooltip: () => void;
  hideTooltip: () => void;
  isTooltipShown: boolean;
  useFloatingData: UseFloatingReturn<HTMLElement>;
  staticSide: string;
  arrowRef: React.MutableRefObject<null>;
  arrowX?: number;
  arrowY?: number;
};

export default function useTooltip(
  placement: Placement = "top"
): UseTooltipData {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const arrowRef = useRef(null);
  const useFloatingData = useFloating<HTMLElement>({
    placement,
    strategy: "fixed",
    open: isTooltipShown,
    middleware: [offset(8), flip(), shift(), arrow({element: arrowRef})],
    whileElementsMounted: (reference, floating, update) =>
      autoUpdate(reference, floating, update, {
        animationFrame: true,
      }),
  });

  const showTooltip = () => setIsTooltipShown(true);
  const hideTooltip = () => {
    setIsTooltipShown(false);
    // reset tootip position to ensure that it will not be under cursor on next appearing
    // otherwise it will trigger hideTooltip (onMouseLeave) right after showTooltip
    useFloatingData.update();
  };

  const {x: arrowX, y: arrowY} = useFloatingData.middlewareData?.arrow || {};

  const staticSide = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[useFloatingData.placement.split("-")[0]] as string;

  return {
    showTooltip,
    hideTooltip,
    isTooltipShown,
    useFloatingData,
    staticSide,
    arrowRef,
    arrowX,
    arrowY,
  };
}
