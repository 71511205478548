import React, {useCallback} from "react";

import cx from "classnames";

import useInputId from "@components/forms/hooks/useInputId";

import styles from "./SwitchInput.scss";

export interface SwitchInputProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  name?: string;
  disabled?: boolean;
  labelOn?: string;
  labelOff?: string;
  [x: string]: any;
}

export default function SwitchInput({
  name = "",
  onChange = () => null,
  value = false,
  disabled = false,
  labelOff = "off",
  labelOn = "on",
  ...otherProps
}: SwitchInputProps): JSX.Element {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked),
    [onChange]
  );

  const inputId = useInputId(name);

  return (
    <label className={styles.switch} htmlFor={inputId}>
      <input
        type="checkbox"
        onChange={handleChange}
        checked={value}
        disabled={disabled}
        name={name}
        className={cx(styles.switchInput, value && styles.checked)}
        id={inputId}
        value={String(value)}
        {...otherProps}
      />
      <span
        className={styles.switchLabel}
        data-on={labelOn}
        data-off={labelOff}
      ></span>
      <span className={styles.switchHandle}></span>
    </label>
  );
}
