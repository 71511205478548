import React from "react";

import cx from "classnames";

import styles from "./NomiconIcon.scss";

const NomiconIcon = ({
  icon,
  className,
  testId,
}: {
  icon: string;
  className?: string;
  testId?: string;
}): JSX.Element => (
  <i
    data-testid={testId}
    className={cx(styles.nomicon, styles[icon], className)}
  ></i>
);

export default NomiconIcon;
