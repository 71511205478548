import React from "react";

import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";

import {setConsumerHeader} from "@globals/utils/setConsumerHeader";

import LoginApp from "./LoginApp";

setConsumerHeader();

const root = createRoot(document.getElementById("root")!);

root.render(
  <BrowserRouter>
    <LoginApp />
  </BrowserRouter>
);
