import React, {ReactNode} from "react";

import cx from "classnames";

import useInputId from "@components/forms/hooks/useInputId";

import styles from "./RadioButton.scss";
import {RadioButtonColor, UncheckedRadioButtonColor} from "./interfaces";

export interface RadioButtonProps {
  name: string;
  value: string | number;
  label: ReactNode;
  onChange: (value: string | number) => void;
  disabled?: boolean;
  checked?: boolean;
  color?: RadioButtonColor;
  uncheckedColor?: UncheckedRadioButtonColor;
  className?: string;
  labelClassName?: string;
}

function RadioButton({...props}: RadioButtonProps): JSX.Element {
  const inputId = useInputId(`${props.name}-${props.value}`);
  return (
    <div className={cx(styles.radioButton, props.className)}>
      <input
        id={inputId}
        className={cx(
          props.color === RadioButtonColor.AccentOrange && styles.accentOrange,
          props.uncheckedColor === UncheckedRadioButtonColor.LightGray &&
            styles.uncheckedLight
        )}
        type="radio"
        disabled={props.disabled}
        value={props.value}
        checked={props.checked}
        onChange={() => props.onChange(props.value)}
        name={props.name}
        data-testid={`${props.name}-${props.value}`}
      />
      <label htmlFor={inputId} className={props.labelClassName}>
        {props.label}
      </label>
    </div>
  );
}

export default RadioButton;
