/**
 * File containing all global configurations, that should be the same across different apps within the repository,
 * for example:
 * - polyfills
 * - Sentry configuration
 */
// Required polyfills (for Sentry, IE11/Edge support)
import * as Sentry from "@sentry/react";
import "core-js/stable";

import {isProduction} from "@globals/config/environment_util";
import {Environment} from "@globals/enums";

Sentry.init({
  dsn: "https://80409f8beec4426585a51735d437096b@sentry.io/197947",
  environment: isProduction() ? `production` : `development`,

  integrations: [
    // turn off capturing console calls in test environment
    new Sentry.Integrations.Breadcrumbs({
      console: ENVIRONMENT !== Environment.Test,
    }),
  ],

  beforeSend(event) {
    // Don't send localhost errors to Sentry
    return isProduction() ? event : null;
  },
});
