import React, {ReactNode} from "react";

import SupportEmail from "@components/SupportEmail";

export default function formatError({
  status,
  message,
}: {
  status: number;
  message: string;
}): ReactNode {
  switch (status) {
    case 472:
      return `Couldn't find your EAT Club account`;
    case 473:
      if (message) {
        return <span dangerouslySetInnerHTML={{__html: message}}></span>;
      }
      return `Incorrect login information`;
    case 200:
      // login succeeded but can_serve is false so we logged the user out
      return (
        <>
          Please contact your administrator or <SupportEmail />
        </>
      );
    default:
      // 400 - bad request or any other issue
      return "Ooops! Something went wrong";
  }
}
