import React, {useMemo} from "react";

import cx from "classnames";

import {HeaderLinkOptions} from "../interfaces";
import styles from "./HeaderLink.scss";

interface HeaderLinkProps {
  url: string;
  text: string;
  typeOfLink: HeaderLinkOptions;
  className?: string;
  onClickHandler?: () => void;
  isSelected?: boolean;
}

const HeaderLink = ({
  url,
  text,
  typeOfLink,
  onClickHandler,
  className,
  isSelected = false,
}: HeaderLinkProps): JSX.Element => {
  const linkClassName = useMemo<string>(() => {
    switch (typeOfLink) {
      case HeaderLinkOptions.FloatingLink:
        return cx(styles.dropdownOption, styles.navigationLinks);
      case HeaderLinkOptions.DropdownOptionLink:
        return styles.dropdownOption;
      case HeaderLinkOptions.NavigationLink:
        return styles.navigationDropdownLinks;
      default:
        return "";
    }
  }, [typeOfLink]);

  return (
    <a
      onClick={onClickHandler}
      href={url}
      target="_self"
      className={cx(
        linkClassName,
        className,
        isSelected && styles.selectedHeaderLink
      )}
      title={HeaderLinkOptions.DropdownOptionLink && text}
      role="menuitem"
    >
      <span>{text}</span>
    </a>
  );
};

export default HeaderLink;
