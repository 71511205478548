import {useEffect} from "react";

export default function usePreloadSprites(sprites: string[]): void {
  useEffect(() => {
    sprites.forEach((sprite) => {
      // to trigger loading of the animation img
      const img = new Image();
      img.src = sprite;
    });
  }, sprites);
}
