import React from "react";

import {EventOrigin} from "@globals/config/eventUtils";

import FavoriteIcon from "@components/FavoriteIcon/FavoriteIcon";

import {DjangoNotificationVerbType, NotificationItem} from "../../interfaces";
import styles from "../ContentStyles.scss";

const DjangoNotificationContent = ({
  notification,
}: {
  notification: NotificationItem;
}): JSX.Element => {
  return (
    <>
      <div
        className={styles.notificationContent}
        dangerouslySetInnerHTML={{
          __html: notification.content as string,
        }}
      ></div>
      {notification.verb === DjangoNotificationVerbType.FavoriteRepeatItem && (
        <div className={styles.notificationFav}>
          <span className={styles.notificationFavText}>
            {"Click here to favorite:"}
          </span>
          <FavoriteIcon
            eventOrigin={EventOrigin.NOTIFICATION}
            itemId={notification.actionObject?.item_id as number}
          />
        </div>
      )}
      {!notification.isToday && (
        <span className={styles.notificationDate}>
          {notification?.displayDate}
        </span>
      )}
    </>
  );
};

export default DjangoNotificationContent;
