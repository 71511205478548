import React, {useCallback, useRef, useState} from "react";

import cx from "classnames";

import useOnClickOutside from "@globals/hooks/useOnClickOutside";

import Button from "@components/Button";
import ErrorWithTooltip from "@components/ErrorWithTooltip";
import NomiconIcon from "@components/NomiconIcon";
import TransparentButton from "@components/TransparentButton";
import useInputId from "@components/forms/hooks/useInputId";

import styles from "./EditTextInput.scss";

export interface EditTextInputProps {
  name?: string;
  onChange?: (value: string) => void;
  onSave: (value: string) => void;
  inputClassName?: string;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  minLength?: number;
  maxLength?: number;
  type?: string;
  iconCustomClass?: string;
  error?: string;
  className?: string;
  emptyValuePlaceholder?: string;
  saveBtnClassName?: string;
}

export default function EditTextInput({
  inputClassName,
  readOnly = false,
  placeholder = "",
  name,
  value,
  onChange,
  onSave,
  type = "text",
  error,
  iconCustomClass,
  saveBtnClassName,
  disabled = false,
  emptyValuePlaceholder,
  ...props
}: EditTextInputProps): JSX.Element {
  const inputId = useInputId(name);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const refWrapper = useRef<HTMLInputElement>(null);
  const refInput = useRef<HTMLInputElement>(null);

  const exitEditing = () => {
    setIsEditing(false);
  };

  useOnClickOutside(refWrapper, exitEditing);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      typeof onChange === "function" && onChange(e.target.value);
    },
    [onChange]
  );

  const handleSave = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (refInput.current) {
        onSave(refInput.current.value);
        exitEditing();
      }
    },
    [onSave]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === "Enter" && refInput.current) {
        onSave(refInput.current.value);
        exitEditing();
      }
    },
    [onSave]
  );

  const handleEdit = () => {
    setIsEditing(true);

    // wait till the input renders
    setTimeout(() => {
      refInput.current?.focus();
    }, 0);
  };

  return (
    <div
      ref={refWrapper}
      className={cx(styles.editTextWrapper, props.className)}
    >
      {disabled && (
        <span className={inputClassName}>{value || emptyValuePlaceholder}</span>
      )}
      {!disabled && !isEditing && (
        <TransparentButton
          onClick={handleEdit}
          className={cx(styles.editButton)}
          title="Edit"
        >
          <span className={inputClassName}>
            {value || emptyValuePlaceholder}
          </span>
          <NomiconIcon
            icon="nomicon-pen"
            className={cx(styles.editButtonIcon, iconCustomClass)}
          />
        </TransparentButton>
      )}
      {isEditing && (
        <>
          <input
            {...props}
            ref={refInput}
            type={type}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            readOnly={readOnly}
            defaultValue={value}
            className={inputClassName}
            id={inputId}
            onChange={handleChange}
            disabled={disabled}
          />
          <Button
            onClick={handleSave}
            className={cx(styles.saveButton, saveBtnClassName)}
            type="button"
          >
            SAVE
          </Button>
        </>
      )}
      <ErrorWithTooltip isError={Boolean(error)} errorMessage={error} />
    </div>
  );
}
