import React, {ComponentType} from "react";

import ErrorBoundaryFallback from "./ErrorBoundaryFallback";

export default function withErrorFallback<
  TProps extends Record<string, unknown>
>(
  WrappedComponent: ComponentType<TProps>,
  FallbackComponent?: ComponentType
): ComponentType<TProps> {
  const fallback = FallbackComponent ? <FallbackComponent /> : null;
  function ComponentWithErrorFallback(props: TProps): JSX.Element {
    return (
      <ErrorBoundaryFallback fallback={fallback}>
        <WrappedComponent {...props} />
      </ErrorBoundaryFallback>
    );
  }
  ComponentWithErrorFallback.displayName = `withErrorFallback(${
    WrappedComponent.displayName || WrappedComponent.name
  }`;
  return ComponentWithErrorFallback;
}
