import React from "react";

import cx from "classnames";

import NomiconIcon from "@components/NomiconIcon";
import Tooltip, {TooltipVariant} from "@components/Tooltip";

import styles from "./ErrorWithTooltip.scss";

type ErrorWithTooltipProps = {
  isError: boolean;
  errorMessage?: string;
  className?: string;
  tooltipClassName?: string;
};
export default function ErrorWithTooltip({
  isError,
  errorMessage = "Saving failed, please try again.",
  className,
  tooltipClassName,
}: ErrorWithTooltipProps): JSX.Element | null {
  if (!isError) {
    return null;
  }

  return (
    <Tooltip
      content={errorMessage}
      variant={TooltipVariant.LightOrange}
      placement="bottom"
      className={tooltipClassName}
    >
      <span
        className={cx(styles.container, className)}
        data-testid="trigger-error-tooltip"
      >
        <NomiconIcon icon="nomicon-alert-filled" />
      </span>
    </Tooltip>
  );
}
