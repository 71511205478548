import {AxiosResponse} from "axios";

import {eatClubClient} from "@globals/config/apiClient";

export function addFavorite(id: number): Promise<AxiosResponse<void>> {
  const url = `/api/v3/favorite-items/${id}/`;

  return eatClubClient.post(url);
}

export function removeFavorite(id: number): Promise<AxiosResponse<void>> {
  const url = `/api/v3/favorite-items/${id}/`;

  return eatClubClient.delete(url);
}
