import {ReactNode} from "react";

import {Placement} from "@floating-ui/react-dom";

export enum TooltipVariant {
  Default = "default",
  LightOrange = "light-orange",
  LightGreen = "light-green",
  BorderedWhite = "bordered-white",
}

export type TooltipProps = {
  children: JSX.Element;
  content: ReactNode;
  className?: string;
  placement?: Placement;
  variant?: TooltipVariant;
};
