import {eatClubClient} from "@globals/config/apiClient";

// no is-consumer-app header needed for opeartor usage only apps
export const setConsumerHeader = (): void => {
  eatClubClient.interceptors.request.use(function (request) {
    request.headers["X-Is-Consumer-App"] = "1";

    return request;
  });
};
