import React from "react";

import Notification from "../Notification/Notification";
import {NotificationItem, NotificationType} from "../interfaces";
import styles from "./NotificationSection.scss";

const NotificationSection = ({
  notifications,
  title,
  dismissNotification,
}: {
  notifications: Array<NotificationItem>;
  title: string;
  dismissNotification: (id: number, notificationType: NotificationType) => void;
}): JSX.Element => (
  <>
    <h4 className={styles.notificationGroupHeader}>{title}</h4>
    <div>
      {notifications.map((notification: NotificationItem) => (
        <Notification
          dismissNotification={dismissNotification}
          notification={notification}
          key={notification.id}
        />
      ))}
    </div>
  </>
);

export default NotificationSection;
