import React, {ReactNode} from "react";

import cx from "classnames";

import styles from "./PageContainer.scss";

type PageContainerProps = {
  children: ReactNode;
  className?: string;
  withHeader?: boolean;
};

export default function PageContainer({
  children,
  className,
  withHeader = false,
}: PageContainerProps): JSX.Element {
  const classname = cx(className, {
    [styles.pageContainer]: !withHeader,
    [styles.pageContainerFullHeight]: withHeader,
  });
  return <div className={classname}>{children}</div>;
}
