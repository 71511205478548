import {UseMutateAsyncFunction} from "@tanstack/react-query";

import {HolidayDetails} from "@globals/hooks/useMenuDates";
import {CheckoutResponse} from "@globals/types/CheckoutResponse";
import {ExtendedError} from "@globals/types/ExtendedError";
import {DateString} from "@globals/types/utilityTypes";

import {Menu, MenuParams} from "@components/Menu/interfaces";

export interface DailyMenuFilters {
  selectedDate?: DateString;
  selectedLocationId?: number;
  isDayClosed: boolean;
  menuDates: DateString[];
  hasNoLunchAccess: boolean;
  preSelectedItem: {
    slug?: string;
    closeCallback: () => void;
  };
  sortOption: number;
  setSortOption: (index: number) => void;
  menu: Menu | undefined;
  hasNoLunchProduct?: boolean;
  latestError: string;
  menuParams: MenuParams;
  closedDates: DateString[];
  holidayDetails: HolidayDetails;
  nextMenuDate?: DateString;
  nextMenuLink?: string;
  goToNextMenuDate: (shouldReplace?: boolean) => void;
  isApiError: boolean;
  isDateAdjusted: boolean;
  clearDayPartSearchParam: () => void;
  // If set, a warning modal is shown
  warning?: string;
  clearWarning: () => void;
}

export enum DailyMenuSearchParam {
  Date = "date",
  Item = "item",
  SortOrder = "sort_order",
  DayPart = "day_part",
}

export enum DailyOrderStatus {
  Active = "active",
  Partial = "partial",
  Cancelled = "cancelled",
}

export const PARTIAL_ORDERS = "PARTIAL_ORDERS";

export type PartialOrderItem = {
  count: number;
  has_side_item: boolean;
  id: number; // menu item id
  name: string;
  price: string;
  special_instructions: string;
  update_message: string;
};

export interface PartialOrder {
  delivery_date: DateString;
  delivery_fee: number;
  driver_gratuity: number;
  id: number;
  location_id: number;
  order_date: DateString;
  order_items: PartialOrderItem[];
  order_status: DailyOrderStatus;
  seconds_to_go: number;
  subtotal: number;
  tax: string;
  total: string;
}

export type EditDailyItemParams = {
  count: number;
  item_id: number; // menu item id
  orderId: number;
  special_instructions?: string;
};

export type AddDailyItemData = {
  item: {
    id: number; // menu item id
    item?: string;
  };
  // Always set when creating Daily Menu order, but not when adding to an existing order
  deliveryDate?: DateString;
  locationId?: number;
};

export type ChangeDailyItemData = AddDailyItemData & {
  orderId: number;
  quantity: number;
  specialInstructions?: string;
};

export type DailyOrdersResource = {
  partialOrders?: PartialOrder[];
  addItem: (itemData: AddDailyItemData) => Promise<PartialOrder>;
  handleItemUpdate: (itemData: ChangeDailyItemData) => Promise<PartialOrder>;
  isLoading: boolean;
  checkout: UseMutateAsyncFunction<CheckoutResponse, Error, void, unknown>;
  latestError?: ExtendedError | null;
  resetOrder: () => void;
};

export type DaySubsidyInfoData = {
  remaining_value: number;
  expiration_date: DateString;
  description: string;
  price_visible: SubsidyPriceVisible;
  mealcap_reached: boolean;
};

export enum SubsidyPriceVisible {
  ShowFullPrice = 1,
  ShowSubsidizedPrice = 2,
  ShowNoPrice = 3,
}

export enum ExceptionType {
  OrderException = "OrderException",
  InventoryException = "InventoryException",
}

export enum ErrorTitle {
  CheckoutGeneric = "Sorry, there was a problem placing that order.",
  ItemGeneric = "Sorry, Something Went Wrong!",
  ItemLargeAmount = "Large orders",
  ItemSoldOut = "Sold Out",
}
