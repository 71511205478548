import React, {useCallback, useEffect, useRef, useState} from "react";

import {useUser} from "@globals/user";

import styles from "./GoogleSignInButton.scss";

const GoogleSigninClientId =
  "89551300844-fkss4st7h944ua0stgbdq80mdus0b9oe.apps.googleusercontent.com";

export default function GoogleSignInButton(): JSX.Element | null {
  const [isGoogleAPILoaded, setIsGoogleAPILoaded] = useState(false);
  const {googleLogin} = useUser();
  const siginButtonRef = useRef<HTMLDivElement | null>(null);

  const onSignIn = useCallback(
    ({credential}: google.accounts.id.CredentialResponse) => {
      googleLogin({token: credential});
    },
    []
  );

  const displayGoogleButton = useCallback(() => {
    setIsGoogleAPILoaded(true);

    google.accounts.id.initialize({
      client_id: GoogleSigninClientId,
      callback: onSignIn,
    });
  }, [setIsGoogleAPILoaded]);

  useEffect(() => {
    if (window.google?.accounts) {
      displayGoogleButton();
    } else {
      (window as any).onGoogleLibraryLoad = displayGoogleButton;
    }
  });

  useEffect(() => {
    if (isGoogleAPILoaded) {
      google.accounts.id.renderButton(siginButtonRef.current as HTMLElement, {
        type: "standard",
      });
      // display the "One Tap" prompt
      google.accounts.id.prompt();
    }
  }, [isGoogleAPILoaded]);

  if (!isGoogleAPILoaded) {
    return null;
  }

  return (
    <>
      <div className={styles.separator}>or</div>
      <div className={styles.signinButtonContainer} ref={siginButtonRef}></div>
    </>
  );
}
