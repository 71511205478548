import React from "react";

import cx from "classnames";

import styles from "./TextInputLayout.scss";

export interface TextInputLayoutProps {
  children: React.ReactNode;
  error?: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  withArrow?: boolean;
  labelFor?: string;
  className?: string;
}

// Layout for all text-input-kind form elements.
// We can reuse it for other types of inputs like phone number or email etc
export default function TextInputLayout({
  children,
  error,
  disabled,
  label,
  required,
  withArrow,
  labelFor,
  className,
}: TextInputLayoutProps): JSX.Element {
  const wrapperClassName = cx(className, {
    [styles.inputField]: true,
    [styles.disabled]: disabled,
    [styles.hasError]: error,
  });

  const controlClassName = cx({
    [styles.controlField]: true,
    [styles.withArrow]: withArrow,
  });

  return (
    <div className={wrapperClassName}>
      {label && (
        <label className={styles.controlLabel} htmlFor={labelFor}>
          {label}
          {required && <span> *</span>}
        </label>
      )}
      {error && (
        <span role="alert" className={styles.errorMessage}>
          {error}
        </span>
      )}
      <div className={controlClassName}>{children}</div>
    </div>
  );
}
