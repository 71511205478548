import React from "react";

import HideText from "@components/HideText";
import NomiconIcon from "@components/NomiconIcon/NomiconIcon";

import {NotificationItem, NotificationType} from "../interfaces";
import DjangoNotificationContent from "./DjangoNotificationContent/DjangoNotificationContent";
import LunchNotificationContent from "./LunchNotificationContent/LunchNotificationContent";
import styles from "./Notification.scss";

const Notification = ({
  notification,
  dismissNotification,
}: {
  notification: NotificationItem;
  dismissNotification: (id: number, notificationType: NotificationType) => void;
}): JSX.Element => {
  return (
    <div className={styles.notification}>
      <div className={styles.notificationTitle}>
        <h5>{notification.title}</h5>
        <div className={styles.notificationDismissBg}>
          <button
            className={styles.notificationDismiss}
            onClick={() =>
              dismissNotification(notification.id, notification.type)
            }
          >
            <HideText>Dismiss notification</HideText>
            <NomiconIcon icon="nomicon-dismiss-filled" />
          </button>
        </div>
      </div>
      {notification.type === NotificationType.Lunch && (
        <LunchNotificationContent notification={notification} />
      )}
      {notification.type === NotificationType.Django && (
        <DjangoNotificationContent notification={notification} />
      )}
    </div>
  );
};

export default Notification;
