import * as yup from "yup";

import {validateFormValues} from "@components/forms/validations/validateFormValues";

export const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup.string().required("Required"),
});

export type FormValues = yup.InferType<typeof validationSchema>;

export const validate = validateFormValues(validationSchema);
