import React from "react";

import styles from "./HideText.scss";

export interface HideTextProps {
  children: string | React.ReactNode;
  hide?: boolean;
}
export default function HideText({
  children,
  hide = true,
}: HideTextProps): JSX.Element {
  return <span className={hide ? styles.hideText : ""}>{children}</span>;
}
