import React, {useCallback, useMemo, useRef, useState} from "react";

import classNames from "classnames";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import useOnClickOutside from "@globals/hooks/useOnClickOutside";
import {DateString} from "@globals/types/utilityTypes";
import toDateString from "@globals/utils/toDateString";

import TransparentButton from "@components/TransparentButton/TransparentButton";
import TextInputLayout from "@components/forms/layouts/TextInputLayout";

import styles from "./DatePickerInput.scss";

export interface DatePickerInputProps {
  value?: DateString;
  locale?: string;
  minDate?: DateString;
  maxDate?: DateString;
  disabled?: boolean;
  onChange?: (date: DateString) => void;
  disabledDates?: DateString[];
  highlightedDates?: DateString[];
  isOpen?: boolean;
  calendarId: string;
  displayFormat?: string;
  withLayout?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
  inputClassName?: string;
  containerClassName?: string;
  calendarClassName?: string;
}

const DatePickerInput = ({
  value,
  locale = "en-US",
  minDate,
  maxDate,
  disabled,
  onChange,
  disabledDates,
  highlightedDates,
  isOpen = false,
  calendarId,
  displayFormat = "dddd, MMMM DD",
  withLayout = false,
  error,
  label,
  required = false,
  inputClassName,
  containerClassName,
  calendarClassName,
}: DatePickerInputProps): JSX.Element => {
  const [showCalendar, setShowCalendar] = useState<boolean>(isOpen);
  const refContainer = useRef<HTMLDivElement>(null);
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  useOnClickOutside(refContainer, () => setShowCalendar(false));

  const handleChange = useCallback(
    (date: Date): void => {
      typeof onChange === "function" && onChange(toDateString(date));
      setShowCalendar(false);
    },
    [onChange]
  );

  useMemo(() => {
    setShowCalendar(isOpen);
  }, [isOpen]);

  const valueForCalendar: Date | undefined = useMemo(() => {
    return value && moment(value).toDate();
  }, [value]);

  const displayedValue: string = useMemo(() => {
    return value ? moment(value).format(displayFormat) : "";
  }, [value]);

  const disableDate = (date: Date): boolean => {
    if (disabledDates?.length) {
      return disabledDates.some((d: DateString) => {
        return d === toDateString(date);
      });
    }
    return false;
  };

  const highlightDate = (date: Date) => {
    let isHighlighted = false;
    if (highlightedDates?.length) {
      isHighlighted = highlightedDates.some((d: DateString) => {
        return d === toDateString(date);
      });
    }
    return isHighlighted ? "highlighted" : "";
  };

  const DatePicker = (
    <div
      ref={refContainer}
      className={classNames(styles.calendarContainer, containerClassName)}
    >
      <TransparentButton
        type="button"
        aria-controls={calendarId}
        aria-expanded={showCalendar}
        aria-label={`Toggle date picker, ${displayedValue} selected`}
        onClick={toggleCalendar}
        className={styles.dateInputWrapper}
      >
        <div
          data-testid={"test-date-input"}
          className={classNames(
            styles.dateInput,
            inputClassName,
            withLayout && styles.variantBig
          )}
        >
          {displayedValue}
        </div>
      </TransparentButton>
      {showCalendar && !disabled && (
        <div id={calendarId}>
          <Calendar
            className={classNames(styles.calendar, calendarClassName)}
            value={valueForCalendar}
            locale={locale}
            minDate={minDate && moment(minDate).toDate()}
            maxDate={maxDate && moment(maxDate).toDate()}
            onChange={handleChange}
            tileDisabled={({date}) => disableDate(date)}
            tileClassName={({date}) => highlightDate(date)}
          />
        </div>
      )}
    </div>
  );

  if (withLayout) {
    return (
      <TextInputLayout
        disabled={disabled}
        label={label}
        error={error}
        required={required}
        labelFor={calendarId}
      >
        {DatePicker}
      </TextInputLayout>
    );
  }

  return DatePicker;
};

export default DatePickerInput;
