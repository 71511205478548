export interface NotificationRecipientResponse {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  selected_location: number;
  payment_group_id: number;
  phone_number: number;
}

export interface DjangoNotificationResponse {
  id: number;
  level: "info";
  unread: boolean;
  verb: DjangoNotificationVerbType;
  description: string;
  timestamp: string;
  public: boolean;
  deleted: boolean;
  data: {
    subject: string;
    body: string;
  };
  recipient: NotificationRecipientResponse;
  actor: unknown;
  target: unknown;
  action_object: null | {
    id?: number;
    item_id?: number;
    is_favorite?: boolean | null;
  };
}

export interface LunchNotificationResponse {
  id: number;
  title: string;
  content: Array<string>;
  show_notification: boolean;
  link?: string;
}

export interface NotificationItem {
  id: number;
  unread: boolean;
  type: NotificationType;
  title: string;
  verb?: string;
  content: Array<string> | string;
  link?: string;
  timestamp?: string;
  isToday: boolean;
  displayDate?: string;
  actionObject?: {
    id?: number;
    item_id?: number;
    isFavorite?: boolean;
  };
}

export enum NotificationType {
  Lunch = "Lunch",
  Django = "Django",
}

export enum DjangoNotificationVerbType {
  DailyFavoriteAlert = "DailyFavoriteAlert",
  FavoriteRepeatItem = "FavoriteRepeatItem",
}
