export interface SiteConfigContextInterface {
  getSupportEmail: () => string;
  isMainEatclubSite: () => boolean;
  getAllergenOverride: (tenantSlug: string) => string | Promise<string>;
  getDishReviewTitle: () => string;
}

export enum SiteConfigMessage {
  SupportEmailAddress = "SUPPORT_EMAIL_ADDRESS",
  AllergenOverride = "ALLERGEN_DISCLAIMER_FOR_SCREENS",
  DishReviewTitle = "DISH_REVIEW_TITLE",
}

export enum Sites {
  MainEatclubSite = "www.eatclub.com",
}
