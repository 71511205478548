import React, {ReactNode, useCallback} from "react";

import cx from "classnames";

import useInputId from "@components/forms/hooks/useInputId";

import styles from "./CheckboxInput.scss";

export interface CheckboxInputProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  name?: string;
  label?: ReactNode;
  disabled?: boolean;
  className?: string;
  isWhiteBackground?: boolean;
  variant?: CheckboxVariant;
  errorText?: string;
}

export enum CheckboxVariant {
  Default = "checkbox-default",
  Teal = "checkbox-teal",
  Error = "checkbox-error",
}

/**
 * CheckboxInput is an HTML component with checked and value attributes taking the same boolean value.
 * The name attribute can be any valid string.
 * But if you want to use it inside Final Form (like CheckBoxField) please consider FinalForm field naming guide https://final-form.org/docs/final-form/field-names
 * It's not recommended to use it in a group of checkboxes with the same name attribute.
 * Because the values are stored with different names in an object.
 * E.g.{location-1-name: true, location-2-name: true}
 * @returns a checkbox input.
 */

export default function CheckboxInput({
  name = "",
  label = "",
  onChange = () => null,
  value = false,
  disabled = false,
  className = "",
  isWhiteBackground = false,
  variant = CheckboxVariant.Default,
  errorText = "",
}: CheckboxInputProps): JSX.Element {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked),
    [onChange]
  );

  const inputId = useInputId(name);

  return (
    <div
      className={cx(styles.checkbox, className, styles[variant], {
        [styles.whiteBackground]: isWhiteBackground,
      })}
    >
      <input
        type="checkbox"
        value={String(value)}
        onChange={handleChange}
        checked={value}
        disabled={disabled}
        name={name}
        id={inputId}
      />
      {label && <label htmlFor={inputId}>{label}</label>}
      {errorText && (
        <span className={styles.checkboxErrorText}> {errorText}</span>
      )}
    </div>
  );
}
