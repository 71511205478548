import {AxiosPromise, AxiosResponse} from "axios";
import moment from "moment";

import {eatClubClient} from "@globals/config/apiClient";

import {
  DjangoNotificationResponse,
  LunchNotificationResponse,
  NotificationItem,
  NotificationType,
} from "./interfaces";

const NOTIFICATION_ID_OFFSET = 110909;

export function getLunchNotifications(): AxiosPromise<Array<NotificationItem>> {
  return eatClubClient
    .get<Array<LunchNotificationResponse>>("/lunch_notifications/")
    .then((result: AxiosResponse<Array<LunchNotificationResponse>>) => {
      return {
        ...result,
        data: result.data.map((notification) => ({
          id: notification.id,
          unread: notification.show_notification,
          type: NotificationType.Lunch,
          title: notification.title,
          content: notification.content,
          link: notification?.link,
          isToday: true,
        })),
      };
    });
}

export function getDjangoNotifications(): AxiosPromise<
  Array<NotificationItem>
> {
  return eatClubClient
    .get<Array<DjangoNotificationResponse>>("/django_notifications/")
    .then((result: AxiosResponse<Array<DjangoNotificationResponse>>) => {
      const today: moment.Moment = moment().startOf("day");
      const yesterday: moment.Moment = moment()
        .subtract(1, "days")
        .startOf("day");

      return {
        ...result,
        data: result.data.map((notification) => {
          const notificationDay: moment.Moment = moment(
            notification.timestamp
          ).startOf("day");

          return {
            id: notification.id,
            unread: notification.unread,
            type: NotificationType.Django,
            verb: notification.verb,
            title: notification.data.subject,
            content: notification.data.body,
            timestamp: notification.timestamp,
            isToday: today.isSame(notificationDay),
            displayDate: yesterday.isSame(notificationDay)
              ? "Yesterday"
              : notificationDay.format("dddd M/D"),
            actionObject: notification.action_object
              ? {
                  id: notification.action_object.id,
                  item_id: notification.action_object.item_id,
                  isFavorite: !!notification.action_object.is_favorite,
                }
              : undefined,
          };
        }),
      };
    });
}

export function getNotifications(): Promise<Array<Array<NotificationItem>>> {
  const arrayOfNotifcationPromises: Array<
    AxiosPromise<Array<NotificationItem>>
  > = [getDjangoNotifications(), getLunchNotifications()];

  return Promise.allSettled(arrayOfNotifcationPromises).then(
    (
      result: Array<
        PromiseSettledResult<AxiosResponse<Array<NotificationItem>>>
      >
    ) => {
      return result
        .filter(
          (promiseSettledItem) => promiseSettledItem.status === "fulfilled"
        )
        .map(
          (promiseFulfilledItem) =>
            (
              promiseFulfilledItem as PromiseFulfilledResult<
                AxiosResponse<Array<NotificationItem>>
              >
            ).value.data
        );
    }
  );
}

export function dismissLunchNotification(id: number): AxiosPromise<void> {
  return eatClubClient.post("/lunch_notifications/", {id});
}

export function dismissDjangoNotification(id: number): AxiosPromise<void> {
  const slug: number = id + NOTIFICATION_ID_OFFSET;

  return eatClubClient.post(`/notifications/delete/${slug}/`);
}

export function markDjangoNotificationsRead(): AxiosPromise<void> {
  return eatClubClient.post("/notifications/mark-all-as-read/");
}
