import React from "react";

import cx from "classnames";

import styles from "./SpriteIcon.scss";
import {SpriteIconsType} from "./interfaces";

type SpriteIconProps = {
  iconName: SpriteIconsType;
  loopOnce?: boolean;
  paused?: boolean;
  onAnimationEnd?: () => void;
};

export default function SpriteIcon({
  iconName,
  loopOnce,
  paused,
  onAnimationEnd,
}: SpriteIconProps): JSX.Element {
  const className = `sprite-icon--${iconName}`;
  return (
    <span className={styles.spriteIconWrapper}>
      <span
        onAnimationEnd={onAnimationEnd}
        className={cx(
          styles[className],
          loopOnce && styles.loopOnce,
          paused && styles.paused
        )}
      ></span>
    </span>
  );
}
