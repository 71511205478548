import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";

import cx from "classnames";

import HideText from "@components/HideText";
import NomiconIcon from "@components/NomiconIcon/NomiconIcon";
import TransparentButton from "@components/TransparentButton";

import styles from "./NumberInput.scss";

/**
 * NumberInput is a component that allows to input specifically a number.
 * Optionally it has two buttons to increase and decrease the number. Controlled by showButtons @prop.
 * Can be used with FinalForm as a NumberField.
 * @method onChange returns a number or a 0 for a non-number values.
 */

type NumberInputProps = {
  name?: string;
  value?: number;
  onChange?: (value: number) => void;
  className?: string;
  showButtons?: boolean;
};

export default function NumberInput({
  value = 0,
  onChange = () => null,
  className,
  name,
  showButtons = true,
}: NumberInputProps): JSX.Element {
  const [localValue, setLocalValue] = useState<number>(value);

  const updateQuantity = (diff: number) => {
    onChange(Math.max(0, localValue + diff));
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    // allow to input only positive intergers
    const inputValue = e.target.value;
    if (inputValue.match(/^\d+$/)) {
      setLocalValue(Number(inputValue));
    } else if (inputValue === "") {
      setLocalValue(0);
    }
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (value !== localValue) {
      onChange(localValue);
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 38) {
      // arrow up
      updateQuantity(+1);
    }
    if (e.keyCode === 40) {
      // arrow down
      updateQuantity(-1);
    }
  };

  useEffect(() => setLocalValue(value), [value]);

  return (
    <div className={cx(styles.container, className)}>
      {showButtons && (
        <TransparentButton
          aria-label="Minus"
          className={cx(styles.roundButton, styles.minusBtn)}
          onClick={() => updateQuantity(-1)}
          data-testid="qty-minus-btn"
          type="button"
        >
          <NomiconIcon icon="nomicon-minus" className={styles.icon} />
        </TransparentButton>
      )}
      <label>
        <HideText>Quantity: </HideText>
        <input
          className={styles.quantityInput}
          value={localValue}
          name={name}
          data-testid={name}
          type="text"
          onChange={onInputChange}
          onBlur={onInputBlur}
          onKeyDown={onKeyDown}
        />
      </label>
      {showButtons && (
        <TransparentButton
          aria-label="Plus"
          className={cx(styles.roundButton, styles.plusBtn)}
          onClick={() => updateQuantity(1)}
          data-testid="qty-plus-btn"
          type="button"
        >
          <NomiconIcon icon="nomicon-plus" className={styles.icon} />
        </TransparentButton>
      )}
    </div>
  );
}
