import React, {useCallback} from "react";

import cx from "classnames";

import useInputId from "@components/forms/hooks/useInputId";
import TextInputLayout from "@components/forms/layouts/TextInputLayout";

import styles from "./TextareaInput.scss";

export interface TextareaInputProps {
  name: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  inputClassName?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  label?: string;
  error?: string;
  minLength?: number;
  maxLength?: number;
  rows?: number;
  cols?: number;
}

export default function TextareaInput({
  inputClassName = "textarea-custom",
  className = "",
  value = "",
  name,
  onChange,
  onBlur,
  ...props
}: TextareaInputProps): JSX.Element {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (typeof onChange === "function") {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    if (typeof onBlur === "function") {
      onBlur();
    }
  }, [onBlur]);

  const inputId = useInputId(name);
  return (
    <TextInputLayout
      disabled={props.disabled}
      label={props.label}
      error={props.error}
      required={props.required}
      labelFor={inputId}
      className={cx(className, styles.textAreaWrapper, {
        [styles.noLabel]: !props.label,
      })}
    >
      <textarea
        {...props}
        className={inputClassName}
        value={value}
        id={inputId}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </TextInputLayout>
  );
}
