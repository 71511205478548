import React from "react";

import {EventOrigin, recordEvent} from "@globals/config/eventUtils";

import {NotificationItem} from "../../interfaces";
import styles from "../ContentStyles.scss";

const LunchNotificationContent = ({
  notification,
}: {
  notification: NotificationItem;
}): JSX.Element => {
  const handleClick: () => void = () => {
    recordEvent(EventOrigin.NOTIFICATION, "notification link", notification.id);
  };

  const lines = (notification.content as Array<string>).map((line, i) => (
    <p key={i} dangerouslySetInnerHTML={{__html: line}}></p>
  ));

  return (
    <div className={styles.notificationContent}>
      {notification?.link ? (
        <a href={notification.link} onClick={handleClick}>
          {lines}
        </a>
      ) : (
        lines
      )}
    </div>
  );
};

export default LunchNotificationContent;
