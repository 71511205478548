import React, {
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

import {configureSentryUser} from "@globals/config/sentryUtils";

import * as UserService from "./UserService";
import {getUserDayParts, handleUserResponseSideEffects} from "./UserService";
import {UserContextType, UserType} from "./interfaces";

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

const USER_QUERY_KEY = ["user"];

export function UserProvider({children}: {children: ReactNode}): JSX.Element {
  const initialData = UserService.getUserCache();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(
    !initialData
  );
  const [userHasChangedLocation, setUserHasChangedLocation] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    data: user,
    isPending,
    isFetched,
    isError,
    error,
    refetch,
  } = useQuery<UserType, Error>({
    queryKey: USER_QUERY_KEY,
    queryFn: UserService.fetchUser,
    initialData,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isFetched) {
      setIsInitialLoading(false);
    }
  }, [isFetched]);

  const removeUserData = () => {
    queryClient.removeQueries({queryKey: USER_QUERY_KEY});
  };

  const loginMutation = useMutation({
    mutationFn: UserService.login,
    onSuccess: refetch,
    onError: removeUserData,
  });

  const googleLoginMutation = useMutation({
    mutationFn: UserService.googleLogin,
    onSuccess: refetch,
    onError: removeUserData,
  });

  const logoutMutation = useMutation({
    mutationFn: UserService.logout,
    onSuccess: removeUserData,
  });

  const setLocationMutation = useMutation({
    mutationFn: UserService.setLocation,
    onSuccess: (response) => {
      const user = response.data;
      handleUserResponseSideEffects(user);
      queryClient.setQueryData(USER_QUERY_KEY, user);
    },
  });

  const mutations = [
    loginMutation,
    googleLoginMutation,
    logoutMutation,
    setLocationMutation,
  ];

  useEffect(() => {
    if (user) {
      configureSentryUser(user);
    }
  }, [user]);

  const setLocation = user
    ? (newLocation: {id: number}) => {
        setUserHasChangedLocation(true);
        return setLocationMutation.mutate({user, newLocation});
      }
    : undefined;

  const memoizedUser = useMemo(
    () => ({
      user: user as UserType,
      isLoading: isPending || mutations.some((mutation) => mutation.isPending),
      isLocationLoading: setLocationMutation.isPending,
      isError: isError || mutations.some((mutation) => mutation.isError),
      error: error || mutations.find((mutation) => mutation.error)?.error,
      isAuthorized: Boolean(user),
      login: loginMutation.mutate,
      googleLogin: googleLoginMutation.mutate,
      logout: logoutMutation.mutate,
      fetchUser: refetch,
      canAccess: (product: string) => UserService.canAccess(user, product),
      isVisible: (product: string) => UserService.isVisible(user, product),
      isLunchVisible: () => UserService.isLunchVisible(user),
      isAdmin: (product: string, allowLimitedAdmin?: boolean) =>
        UserService.isAdmin(user, product, allowLimitedAdmin),
      isLunchAdmin: (allowLimitedAdmin?: boolean) =>
        UserService.isLunchAdmin(user, allowLimitedAdmin),
      hasPPG: (product: string) => UserService.hasPPG(user, product),
      hasSubsidy: (product: string, date: string) =>
        UserService.hasSubsidy(user, product, date),
      hasPDL: (product: string, locationId: number) =>
        UserService.hasPDL(user, product, locationId),
      getHomepage: () => UserService.getHomepage(user),
      setLocation,
      userHasChangedLocation,
      hasAccessToMultipleDayParts: getUserDayParts(user).size > 1,
    }),
    [user, isPending, isError, error, setLocationMutation.isPending]
  );

  return (
    <UserContext.Provider value={memoizedUser}>
      {isInitialLoading || children}
    </UserContext.Provider>
  );
}
