import React, {ReactNode} from "react";

import cx from "classnames";

import styles from "./MaxWidthContainer.scss";

type MaxWidthContainerProps = {
  children: ReactNode;
  className?: string;
  tagName?: keyof JSX.IntrinsicElements;
  // any other props
  [x: string]: any;
};

export default function MaxWidthContainer({
  children,
  className,
  tagName: TagName = "div",
  ...otherProps
}: MaxWidthContainerProps): JSX.Element {
  const classname = cx(className, styles.maxWidthContainer);
  return (
    <TagName className={classname} {...otherProps}>
      {children}
    </TagName>
  );
}
