import React, {Component, ReactNode} from "react";

import * as Sentry from "@sentry/react";

import styles from "./ErrorBoundaryFallback.scss";

type ErrorBoundaryFallbackProps = {
  children: ReactNode;
  fallback?: ReactNode;
};

type ErrorBoundaryFallbackState = {
  hasError: boolean;
};

const FinalFallback = () => {
  return (
    <>
      Sorry, something went wrong and we couldn't load this part of the page!
      Please try refreshing the page. If the issue persists, contact us at{" "}
      <a className={styles.link} href="mailto:help@eatclub.com">
        help@eatclub.com
      </a>
      .
    </>
  );
};

export default class ErrorBoundaryFallback extends Component<
  ErrorBoundaryFallbackProps,
  ErrorBoundaryFallbackState
> {
  constructor(props: ErrorBoundaryFallbackProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(): ErrorBoundaryFallbackState {
    return {hasError: true};
  }

  componentDidCatch(error: Error | string | undefined): void {
    try {
      if (error instanceof Error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureException(new Error(error));
      }
    } catch {
      // If there's an error sending to Sentry we would rather ignore it and
      // show user the fallback boundary instead of crashing
    }
  }

  render(): ReactNode {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return this.props.fallback || <FinalFallback />;
  }
}
