import React from "react";

import BaseApp from "@apps/base/BaseApp";
import {useSearchParams} from "react-router-dom";

import {ACCESS_REDIRECT_URL} from "@globals/constants/localStorageKeys";
import isValidRelativePath from "@globals/functions/isValidRelativePath";

import {AccessRightFlags, CheckAccess} from "@components/CheckAccess";
import Footer from "@components/layout/Footer";
import MaxWidthContainer from "@components/layout/MaxWidthContainer";
import PageContainer from "@components/layout/PageContainer";

import GoogleSignInButton from "./GoogleSignInButton";
import LoginForm from "./LoginForm";
import styles from "./index.scss";

const LoginApp = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const nextPath = searchParams.get("next");
  // valid param should start with a slash;
  const isValidNextPath = isValidRelativePath(nextPath);
  const urlToRedirect: string =
    (isValidNextPath && nextPath) ||
    localStorage.getItem(ACCESS_REDIRECT_URL) ||
    "/";

  return (
    <BaseApp>
      <CheckAccess
        flag={AccessRightFlags.Unauthorized}
        redirectTo={urlToRedirect}
        saveUrl={false}
      >
        <PageContainer className={styles.container}>
          <MaxWidthContainer tagName="main">
            <div className={styles.heading}>Welcome</div>
            <div className={styles.subheading}>Sign in to EAT Club</div>
            <LoginForm />
            <GoogleSignInButton />
          </MaxWidthContainer>
        </PageContainer>
        <Footer />
      </CheckAccess>
    </BaseApp>
  );
};

export default LoginApp;
