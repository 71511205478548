import React, {ReactNode, useMemo} from "react";

import {DaySubsidyInfoData} from "@apps/daily-menu/interfaces";

import logoImageUrl from "@globals/img/logo.svg";
import {useUser} from "@globals/user";

import Button from "@components/Button";
import {AccessRightFlags, CheckAccess} from "@components/CheckAccess";
import DaySubsidyInfo from "@components/DaySubsidyInfo";
import Notifications from "@components/Notifications/Notifications";
import MaxWidthContainer from "@components/layout/MaxWidthContainer";

import styles from "./Header.scss";
import HeaderDropdown from "./HeaderDropdown/HeaderDropdown";
import HeaderLink from "./HeaderLink/HeaderLink";
import {AppRoutes, HeaderLinkOptions} from "./interfaces";

export type HeaderProps = {
  showLoginLink?: boolean;
  selectedHeaderLink?: AppRoutes;
  showMealPrograms?: boolean;
  daySubsidyInfo?: DaySubsidyInfoData;
  children?: string;
  rightSideContent?: ReactNode;
  showNotificationsBell?: boolean;
  showHeaderDropdown?: boolean;
};

/*
 * EAT Club consumer app header. If children are passed, it will display them
 * next to the logo instead of the main links.
 */
export default function Header({
  showLoginLink,
  selectedHeaderLink,
  showMealPrograms = true,
  daySubsidyInfo,
  children,
  rightSideContent,
  showNotificationsBell = true,
  showHeaderDropdown = true,
}: HeaderProps): JSX.Element {
  const {user, isLunchVisible, isLunchAdmin, getHomepage, logout} = useUser();
  const hasTitle = Boolean(children);

  const adminDashUrl: string = useMemo(() => {
    if (isLunchAdmin(true)) {
      return "/admin-dash/lunch/";
    }
    return "/admin-dash/";
  }, [user]);

  return (
    <header className={styles.header}>
      <MaxWidthContainer className={styles.wrapper}>
        <nav className={styles.headerNavigationSection} aria-label="Site">
          <div className={styles.headerLeftNavigation}>
            <a href={getHomepage()} target="_self" className={styles.logoLink}>
              <img
                className={styles.logoImage}
                src={logoImageUrl}
                alt="Eat Club"
              />
            </a>
            {hasTitle ? (
              <span className={styles.title}>{children}</span>
            ) : (
              <CheckAccess flag={AccessRightFlags.Authorized}>
                <>
                  {isLunchVisible() && (
                    <HeaderLink
                      url={`/${AppRoutes.Menu}/`}
                      text={"Daily Menu"}
                      typeOfLink={HeaderLinkOptions.NavigationLink}
                      className={styles.leftNavigationLink}
                      isSelected={selectedHeaderLink === AppRoutes.Menu}
                    />
                  )}
                  <CheckAccess flag={AccessRightFlags.IsMasterAccountHolder}>
                    <HeaderLink
                      url={adminDashUrl}
                      text={"Admin Dashboard"}
                      typeOfLink={HeaderLinkOptions.NavigationLink}
                      className={styles.leftNavigationLink}
                      isSelected={
                        selectedHeaderLink === AppRoutes.AdminDashboard
                      }
                    />
                  </CheckAccess>
                </>
              </CheckAccess>
            )}

            <CheckAccess flag={AccessRightFlags.Unauthorized}>
              {showMealPrograms && (
                <HeaderLink
                  url={"/"}
                  text={"Meal Programs"}
                  typeOfLink={HeaderLinkOptions.NavigationLink}
                  className={styles.leftNavigationLink}
                />
              )}
            </CheckAccess>
          </div>
          <div className={styles.rightSideNavigation}>
            {rightSideContent && (
              <div className={styles.additionalRightSideContent}>
                {rightSideContent}
              </div>
            )}
            {daySubsidyInfo && (
              <DaySubsidyInfo daySubsidyInfo={daySubsidyInfo} />
            )}
            {showNotificationsBell && isLunchVisible() && <Notifications />}
            {showHeaderDropdown ? (
              <HeaderDropdown
                showLoginLink={showLoginLink}
                adminDashUrl={adminDashUrl}
              />
            ) : (
              <CheckAccess flag={AccessRightFlags.Authorized}>
                <Button
                  onClick={() => {
                    logout({redirect: true});
                  }}
                >
                  Logout
                </Button>
              </CheckAccess>
            )}
          </div>
        </nav>
      </MaxWidthContainer>
    </header>
  );
}
