import React from "react";

import {useSiteConfig} from "@globals/SiteConfig";

type SupportEmailProps = {
  isLink?: boolean;
  className?: string;
  target?: string;
  linkText?: string;
};

export default function SupportEmail({
  isLink = false,
  className,
  target = "_blank",
  linkText,
}: SupportEmailProps): JSX.Element {
  const {getSupportEmail} = useSiteConfig();
  const email = getSupportEmail();

  if (isLink) {
    return (
      <a href={`mailto:${email}`} className={className} target={target}>
        {linkText ?? email}
      </a>
    );
  }

  return <>{email}</>;
}
