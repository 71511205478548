import {groupBy} from "lodash";

import {LocationType, UserType} from "@globals/user/interfaces";

export const getLocationForUser = (
  user?: UserType,
  specificLocationId?: number
): LocationType | undefined => {
  const locationId = specificLocationId ?? user?.selected_location.id;
  return user?.locations_list.find((location) => location.id === locationId);
};

/**
 * Finds all day parts user has access to at a given location, or their current
 * selected location.
 */
export const getDayPartLocationsForLocation = (
  user?: UserType,
  locationId?: number
) => {
  const sourceLocation = getLocationForUser(user, locationId);
  if (!sourceLocation) return [];
  const {state, address, city, zip} = sourceLocation;
  return (
    user?.locations_list.filter((location) => {
      return (
        location.state === state &&
        location.address === address &&
        location.city === city &&
        location.zip === zip
      );
    }) || []
  );
};

export const getDayPartLocation = (user: UserType, dayPart: string) => {
  if (!user?.selected_location) return undefined;
  const dayPartLocations = getDayPartLocationsForLocation(user);
  return dayPartLocations?.find((location) => {
    return location.day_part.toLowerCase() == dayPart.toLowerCase();
  });
};

export const getAllDayPartsForUser = (user: UserType) => {
  return new Set(user.locations_list.map((location) => location.day_part));
};

export const groupByPhysicalAddress = (locations: LocationType[]) => {
  return groupBy(locations, function (location) {
    return [location.state, location.address, location.city, location.zip];
  });
};
