import React from "react";

import cx from "classnames";

import footerLogoImageUrl from "@globals/img/logo-white-text.svg";

import SupportEmail from "@components/SupportEmail";

import styles from "./Footer.scss";

export default function FooterFallback(): JSX.Element {
  const PRIVACY_REQUEST_URL = `\
https://privacyportal-eu-cdn.onetrust.com/dsarwebform/8394ad8c-2b46-4837-8771-cbc69779\
a644/31bea1f4-92c3-440b-be0e-468af4f4b1f3.html?Source=EATClub`;
  const currentYear: number = new Date().getFullYear();

  return (
    <footer className={styles.globalFooterWrapper}>
      <div className={styles.globalFooter}>
        <div
          className={cx(styles.footerAddress, styles.footerSectionTopBorder)}
        >
          <p>
            2400 Yorkmont Rd, Charlotte, NC 28217
            <br />
            ©EAT Club {currentYear}
          </p>
        </div>
        <div className={styles.footerLogo}>
          <img src={footerLogoImageUrl} width="110" alt="EAT Club" />
        </div>
        <div
          className={cx(styles.footerCopyright, styles.footerSectionTopBorder)}
        >
          <p>
            <SupportEmail linkText="Contact us" isLink /> |{" "}
            <a href="/privacy">Privacy Policy</a> |{" "}
            <a href={PRIVACY_REQUEST_URL}>Privacy Request</a> |{" "}
            <a href="/terms">Terms of Service</a>
          </p>
        </div>
      </div>
    </footer>
  );
}
