import React, {useCallback, useState} from "react";

import cx from "classnames";
import {range} from "lodash";

import HideText from "@components/HideText";

import styles from "./StarsInput.scss";

export interface StarsInputProps {
  name: string;
  onChange?: (value?: number) => void;
  required?: boolean;
  value?: number;
  showTooltip?: boolean;
  onStarClick?: () => void;
  className?: string;
}

const tooltipArray = [
  "I didn't like it",
  "Wasn't my favorite",
  "It was okay",
  "I liked it",
  "I loved it",
];

export default function StarsInput({
  value = 0,
  name,
  onChange,
  showTooltip,
  required,
  onStarClick,
  className,
}: StarsInputProps): JSX.Element {
  const [starsHovered, setStarsHovered] = useState(0);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function") {
        onChange(Number(e.target.value));
      }
    },
    [onChange]
  );

  const getRatingTip = (index: number) => {
    return tooltipArray[index - 1];
  };

  const leaveHandler = () => {
    setStarsHovered(0);
  };

  const enterHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    setStarsHovered(Number(e.currentTarget.value));
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onStarClick === "function") {
      onStarClick();
    }
  };

  const renderStar = (index: number) => {
    const num = index + 1;
    return (
      <label className={cx({[styles.selected]: num <= value})} key={num}>
        {/* display number of stars for screen readers */}
        <HideText>{num} out of 5 stars</HideText>
        <input
          required={required}
          type="radio"
          name={name}
          value={num}
          onMouseUp={handleMouseUp}
          onChange={handleChange}
          onMouseEnter={enterHandler}
          onMouseLeave={leaveHandler}
          checked={num === value}
        />
      </label>
    );
  };

  return (
    <fieldset className={cx(styles.starRating, className)}>
      {/* display fieldset legend for screen readers */}
      <legend>
        <HideText>Your rating:</HideText>
      </legend>
      <div className={styles.starsContainer}>
        {range(5).map((i) => renderStar(i))}
      </div>
      <span className={styles.ratingTip}>
        {showTooltip && (
          <span className={styles.ratingTipContent}>
            {getRatingTip(starsHovered)}
          </span>
        )}
      </span>
    </fieldset>
  );
}
