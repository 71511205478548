import React, {useMemo} from "react";

import cx from "classnames";
import {FieldRenderProps} from "react-final-form";

import styles from "./BaseFieldLayout.scss";

export interface BaseFieldLayoutProps {
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
}

export default function BaseFieldLayout({
  label,
  required = false,
  inputComponent: InputComponent,
  meta,
  input,
  disabled = false,
  wrapperClassName,
  ...rest
}: FieldRenderProps<BaseFieldLayoutProps>): JSX.Element {
  const error = useMemo(() => {
    if (meta.submitError && !meta.dirtySinceLastSubmit) {
      return meta.submitError;
    }
    if (meta.error && meta.touched) {
      return meta.error;
    }
  }, [meta.error, meta.touched, meta.dirtySinceLastSubmit, meta.submitError]);
  const formattedError = useMemo(
    () => (Array.isArray(error) ? error[0] : error),
    [error]
  );

  return (
    <div className={cx(styles.formGroup, wrapperClassName)}>
      <InputComponent
        {...rest}
        {...input}
        error={formattedError}
        label={label}
        required={required}
        disabled={disabled}
      />
    </div>
  );
}
