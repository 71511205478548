import {recordBreadcrumbToSentry} from "./sentryUtils";

declare let dataLayer: Record<string, unknown>[];

export enum EventOrigin {
  PAGE = "page",
  QUICKVIEW = "quickview",
  NOTIFICATION = "notification",
  CART = "cart",
}

export function recordEvent(
  origin: EventOrigin,
  action: string,
  value: unknown,
  additionalData?: Record<string, unknown>
): void {
  recordBreadcrumbToSentry({
    message: action,
    category: "event",
    data: {value: value},
  });

  dataLayer.push({
    event: "gaEvent",
    eventOrigin: origin,
    eventAction: action,
    eventValue: value,
    ...additionalData,
  });
}

export type ConversionItem = {
  item_id: string;
  item_name: string;
  quantity: number;
};

export function recordConversionEvent(
  origin: EventOrigin,
  action: string,
  value: unknown,
  data: {
    items: ConversionItem[];
    [key: string]: unknown;
  }
): void {
  // required params for conversion event;
  const conversionData = {
    currency: "USD",
    value: Number(value) || 0,
    ...data,
  };
  recordEvent(origin, action, value, conversionData);
}
