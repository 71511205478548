import React, {ReactNode} from "react";

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import {AppConfigProvider} from "@globals/AppConfig";
import {SiteConfigProvider} from "@globals/SiteConfig";
import spritesToPreload from "@globals/constants/spritesToPreload";
import usePreloadSprites from "@globals/hooks/usePreloadSprites";
import {UserProvider} from "@globals/user";

import ErrorBoundary, {withErrorFallback} from "@components/ErrorBoundary";
import Header from "@components/layout/Header";
import StaffInfoBanner from "@components/layout/Header/StaffInfoBanner/StaffInfoBanner";
import {AppRoutes} from "@components/layout/Header/interfaces";

import styles from "./BaseApp.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

type BaseAppProps = {
  children: ReactNode;
  includeHeader?: boolean;
  showLoginLink?: boolean;
  selectedHeaderLink?: AppRoutes;
  isStaffInfoRelativePosition?: boolean;
};

const BaseApp = ({
  children,
  includeHeader = true,
  showLoginLink = false,
  selectedHeaderLink,
  isStaffInfoRelativePosition = false,
}: BaseAppProps): JSX.Element => {
  usePreloadSprites(spritesToPreload);

  return (
    <AppConfigProvider>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <SiteConfigProvider>
            <ErrorBoundary>
              <StaffInfoBanner
                isStaffInfoRelativePosition={isStaffInfoRelativePosition}
              />
              {includeHeader && (
                <Header
                  showLoginLink={showLoginLink}
                  selectedHeaderLink={selectedHeaderLink}
                />
              )}
              {children}
            </ErrorBoundary>
          </SiteConfigProvider>
        </UserProvider>
      </QueryClientProvider>
    </AppConfigProvider>
  );
};

export default withErrorFallback(BaseApp, () => (
  <>
    Sorry, something went wrong! Please try refreshing the page. If the issue
    persists, contact us at{" "}
    <a className={styles.link} href="mailto:help@eatclub.com">
      help@eatclub.com
    </a>
    .
  </>
));
