import * as Sentry from "@sentry/react";
import axios from "axios";
import Cookies from "js-cookie";

import {Environment} from "@globals/enums";

import "./global_config_react";

export const eatClubClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

eatClubClient.interceptors.response.use(function (response) {
  if (ENVIRONMENT === Environment.Development) {
    // CORS workaround for local development against HTTPS sandbox.
    // It will be stripped for production/sandbox code.
    const csrfToken = response.headers["x-csrftoken"];
    if (csrfToken) {
      Cookies.set("csrftoken", csrfToken, {path: "/"});
    }
  }

  return response;
});

eatClubClient.interceptors.request.use(function (request) {
  request.headers["X-CSRFToken"] = Cookies.get("csrftoken");
  return request;
});

eatClubClient.interceptors.response.use(undefined, (error) => {
  if (!error?.response) {
    // If response object doesn't exist (eg. due to device Network Error), simply
    // propagate the rejection.
    return Promise.reject(error);
  }

  const method = error.config.method.toUpperCase();
  const url = error.config.url;
  const responseStatus = error.response.status;
  const responseData = error.response.data || {};

  if (responseStatus >= 500) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        fingerprint: [url, responseStatus],
        extra: {
          response_data: responseStatus === 500 ? null : responseData,
          request_data: responseData,
          request_params: error.config.params,
        },
      });
      const message = `Error: ${method} ${url} ${responseStatus}`;

      Sentry.captureMessage(message);
    });
  }

  return Promise.reject(error);
});
