export default function formatCurrency(
  value?: number,
  currency = "USD"
): string {
  if (value === undefined || value === null) {
    return "";
  }
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency,
  }).format(value);
}
