import React, {useEffect} from "react";

import {Form} from "react-final-form";

import {useUser} from "@globals/user";

import Button from "@components/Button";
import {TextField} from "@components/forms";

import styles from "./LoginForm.scss";
import formatError from "./utils/formatError";
import {validate} from "./utils/validations";

export default function LoginForm(): JSX.Element {
  const {isAuthorized, isLoading, isError, error, login} = useUser();

  useEffect(() => {
    if (isAuthorized) {
      window.location.assign("/");
    }
  }, [isAuthorized]);

  return (
    <div className={styles.formContainer}>
      {isError && <div className={styles.baseError}>{formatError(error)}</div>}
      <Form
        onSubmit={login}
        validate={validate}
        render={({handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
            <TextField name="email" type="email" label="Email" autoFocus />
            <TextField name="password" type="password" label="Password" />
            <div className={styles.resetPassword}>
              <a href="/forgot/">Forgot your password?</a>
            </div>
            <Button
              className={styles.loginBtn}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Sign in"}
            </Button>
          </form>
        )}
      ></Form>
    </div>
  );
}
