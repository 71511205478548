import React, {useCallback} from "react";

import useInputId from "@components/forms/hooks/useInputId";

import TextInputLayout from "../layouts/TextInputLayout";

export interface TextInputProps {
  name: string;
  onChange?: (value: string) => void;
  inputClassName?: string;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  label?: string;
  error?: string;
  minLength?: number;
  maxLength?: number;
  type?: string;
  withLayout?: boolean;
  onClick?: () => void;
  id?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  autoFocus?: boolean;
}

export default function TextInput({
  inputClassName = "input-custom",
  readOnly = false,
  placeholder = "",
  value,
  name,
  onChange,
  type = "text",
  withLayout = true,
  ...props
}: TextInputProps): JSX.Element {
  const inputId = useInputId(name);
  const useLabelFor = props.id ?? inputId;
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === "function") {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  const InputNode = (
    <input
      {...props}
      type={type}
      placeholder={placeholder}
      readOnly={readOnly}
      value={value}
      className={inputClassName}
      id={useLabelFor}
      onChange={handleChange}
      name={name}
    />
  );

  if (withLayout) {
    return (
      <TextInputLayout
        disabled={props.disabled}
        label={props.label}
        error={props.error}
        required={props.required}
        labelFor={useLabelFor}
      >
        {InputNode}
      </TextInputLayout>
    );
  }

  return InputNode;
}
