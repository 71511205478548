import React from "react";

import logoImageUrl from "@globals/img/logo.svg";

import MaxWidthContainer from "@components/layout/MaxWidthContainer";

import styles from "./Header.scss";

export default function HeaderFallback(): JSX.Element {
  return (
    <header className={styles.header}>
      <MaxWidthContainer className={styles.wrapper}>
        <nav className={styles.headerNavigationSection}>
          <div className={styles.headerLeftNavigation}>
            <a href="/" target="_self" className={styles.logoLink}>
              <img
                className={styles.logoImage}
                src={logoImageUrl}
                alt="Eat Club"
              />
            </a>
          </div>
        </nav>
      </MaxWidthContainer>
    </header>
  );
}
