/* eslint-disable max-lines */
import {AxiosResponse} from "axios";
import Cookies from "js-cookie";

import {eatClubClient} from "@globals/config/apiClient";
import {EventOrigin, recordEvent} from "@globals/config/eventUtils";
import {configureSentryUser} from "@globals/config/sentryUtils";

import {Product, UserType} from "./interfaces";

declare let dataLayer: Record<string, any>[];

function setUserAnalyticsProps(user?: UserType): void {
  const isBatcavePage = window.location.pathname.includes("/batcave/");

  if (user) {
    if (isBatcavePage) {
      dataLayer.push({
        event: "trackerReady",
        user_id: user.id,
        operator_name: user.operator,
      });
      // don't set other user data for Batcave
      return;
    }
    dataLayer.push({
      user_id: user.id,
      user_company_name: user.company?.name,
    });
  } else {
    dataLayer.push({
      user_id: null,
      user_company_name: null,
      operator_name: null,
    });
  }
}

export function setUserCache(data: UserType): void {
  localStorage.user_cache = JSON.stringify(data);
}

export function clearUserCache(): void {
  delete localStorage.user_cache;
}

export function getUserCache(): UserType | undefined {
  const cachedUser = localStorage.user_cache
    ? JSON.parse(localStorage.user_cache)
    : undefined;
  return cachedUser || undefined;
}

export function handleUserResponseSideEffects(user: UserType): void {
  setUserCache(user);
  // add user info to the dataLayer
  setUserAnalyticsProps(user);
}

export function fetchUser(): Promise<UserType> {
  if (Cookies.get("user_logged_out")) {
    clearUserCache();
    Cookies.remove("user_logged_out", {path: "/"});
    // clear user info in the dataLayer
    setUserAnalyticsProps();
  }

  return eatClubClient
    .get("/member/api/user/")
    .then(function (response) {
      const user = response.data;
      handleUserResponseSideEffects(user);
      return user;
    })
    .catch(function (error) {
      clearUserCache();

      if (error.response.status !== 401) {
        throw error;
      }
      return null;
    });
}

export function logout({
  redirect,
  globalLogout = true,
}: {redirect?: boolean; globalLogout?: boolean} = {}): Promise<void> {
  let signingOut = Promise.resolve();

  if (globalLogout) {
    clearUserCache();
    recordEvent(EventOrigin.PAGE, "logout", "1");
    signingOut = signingOut.finally(() =>
      eatClubClient.put("/api/v3/log_out/")
    );
  }

  if (redirect) {
    signingOut = signingOut.then(() => {
      window.location.assign("/login/");
    });
  }

  return signingOut;
}

export function handleLoggedIn(response: AxiosResponse): void {
  Cookies.set("block_hp", "1", {expires: 3650, path: "/"});
  configureSentryUser(response.data);
}

export function login({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<any> {
  return eatClubClient
    .put("/public/api/log-in/", {email, password})
    .then((response) => {
      if (!response.data.can_serve) {
        void logout({redirect: false});
        const error = {response};
        throw error;
      }

      recordEvent(EventOrigin.PAGE, "login", "password");

      if (response.data.home_url) {
        window.location.assign(response.data.home_url);
      }

      handleLoggedIn(response);
    })
    .catch(({response}) => {
      const error = {
        status: response.status,
        message: response.data?.message,
      };
      throw error;
    });
}

export function googleLogin({token}: {token: string}): Promise<any> {
  return eatClubClient
    .put("/public/api/google-log-in/", {token})
    .then(function (response) {
      if (!response.data.can_serve) {
        void logout({redirect: false});
        const error = {response};
        throw error;
      }

      recordEvent(EventOrigin.PAGE, "login", "google");

      if (response.data.home_url) {
        window.location.assign(response.data.home_url);
      }

      return response;
    })
    .then(handleLoggedIn)
    .catch(({response}) => {
      if (response.status !== 200) {
        void logout({redirect: false, globalLogout: false});
      }
      const error = {
        status: response.status,
        message: response.data?.message,
      };
      throw error;
    });
}

export function getLocation(): Promise<AxiosResponse> {
  return eatClubClient.get("/accounts/location/");
}

export function setLocation({
  user,
  newLocation,
}: {
  user: UserType;
  newLocation: {id: number};
}): Promise<any> {
  return eatClubClient.put(`/member/api/user/${user.id}/`, {
    selected_location: {id: newLocation.id},
  });
}

export function getEnableBuplan(): Promise<AxiosResponse> {
  return eatClubClient.get("/user/preferences/");
}

export function hasPPG(
  user: UserType | undefined,
  productName: string
): boolean {
  return Boolean(
    user?.ppg_products.some(({product}) => product.name === productName)
  );
}

export function hasPDL(
  user: UserType | undefined,
  product: string,
  locationId?: number
): boolean {
  const location = user?.locations_list?.find(({id}) => id === locationId);
  return Boolean(location?.products.includes(product));
}

export function canAccess(
  user: UserType | undefined,
  product: string
): boolean {
  return (
    hasPPG(user, product) && hasPDL(user, product, user?.selected_location.id)
  );
}

export function isVisible(
  user: UserType | undefined,
  product: string
): boolean {
  if (!user?.selected_location) {
    return false;
  }
  return (
    hasPPG(user, product) || hasPDL(user, product, user?.selected_location.id)
  );
}

export function isLunchVisible(user: UserType | undefined): boolean {
  return isVisible(user, Product.Lunch);
}

export function isAdmin(
  user: UserType | undefined,
  product: string,
  allowLimitedAdmin = false
): boolean {
  if (
    !user?.admin_products ||
    (user?.is_limited_master_account_holder && !allowLimitedAdmin)
  ) {
    return false;
  }
  return user?.admin_products.includes(product);
}

export function isLunchAdmin(
  user: UserType | undefined,
  allowLimitedAdmin = false
): boolean {
  return isAdmin(user, Product.Lunch, allowLimitedAdmin);
}

export function hasSubsidy(
  user: UserType | undefined,
  product: string,
  date: string
): boolean {
  return Boolean(user?.subsidized_products[date].includes(product));
}

export function getHomepage(user?: UserType): string {
  if (user?.is_staff) {
    return "/batcave/";
  } else if ((user?.groups?.indexOf("Kitchen") ?? -1) >= 0) {
    return "/batcave/kitchen/";
  } else {
    return "/";
  }
}

export function getUserDayParts(user?: UserType): Set<string> {
  return new Set(user?.locations_list?.map((location) => location.day_part));
}
