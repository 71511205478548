import React, {useEffect, useMemo, useState} from "react";

import cx from "classnames";

import {EventOrigin, recordEvent} from "@globals/config/eventUtils";
import {useUser} from "@globals/user";
import prefersReducedMotion from "@globals/utils/prefersReducedMotion";

import NomiconIcon from "@components/NomiconIcon/NomiconIcon";
import SpriteIcon, {SpriteIconsType} from "@components/SpriteIcon";
import TransparentButton from "@components/TransparentButton";

import styles from "./FavoriteIcon.scss";
import * as FavoriteIconService from "./FavoriteIconService";

export interface FavoriteIconProps {
  itemId: number;
  eventOrigin?: EventOrigin;
  showHoverBox?: boolean;
  className?: string;
  onUnfavorite?: () => void;
  onToggle?: () => void;
}

const FavoriteIcon = ({
  itemId,
  eventOrigin = EventOrigin.PAGE,
  showHoverBox,
  className,
  onUnfavorite,
  onToggle,
}: FavoriteIconProps): JSX.Element => {
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  // local state to avoid glimps while waiting for the response
  const [isFavoriteLocal, setIsFavoriteLocal] = useState<boolean>();
  const {user, fetchUser} = useUser();

  const isFavorite = useMemo(() => {
    return user?.favorite_items.some((item) => item.id === itemId);
  }, [user]);

  useEffect(() => {
    setIsFavoriteLocal(isFavorite);
  }, [isFavorite]);

  const toggleFavorite = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (isFavorite) {
      setIsFavoriteLocal(!isFavorite);
      onUnfavorite?.();
      void FavoriteIconService.removeFavorite(itemId).then(() => {
        recordEvent(eventOrigin, "remove favorite", itemId);
        // update user favorite items
        fetchUser();
      });
    } else {
      void FavoriteIconService.addFavorite(itemId).then(() => {
        if (prefersReducedMotion()) {
          setIsFavoriteLocal(true);
        } else {
          setIsAnimated(true);
        }
        recordEvent(eventOrigin, "add favorite", itemId);
        // update user favorite items
        fetchUser();
      });
    }
    onToggle?.();
  };

  const onAnimationEnd: () => void = () => {
    setIsAnimated(false);
    setIsFavoriteLocal(true);
  };

  return (
    <div className={cx(styles.favoriteIconContainer, className)}>
      <TransparentButton
        data-testid={"favorite"}
        aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
        onClick={toggleFavorite}
        disabled={isAnimated}
      >
        {isAnimated && (
          <span className={styles.spriteContainer}>
            <SpriteIcon
              loopOnce
              onAnimationEnd={onAnimationEnd}
              iconName={SpriteIconsType.HeartExplodeShort}
            />
          </span>
        )}
        {!isAnimated && (
          <NomiconIcon
            icon={isFavoriteLocal ? "nomicon-heart-filled" : "nomicon-heart"}
          />
        )}
      </TransparentButton>
      {showHoverBox && !isFavorite && (
        <>
          <div className={styles.favoritesHoverBox}>
            {user?.favorite_items.length === 0
              ? "Save to favorites and be notified next time"
              : "Save to my favorites"}
          </div>
          <div className={styles.arrowRight}></div>
        </>
      )}
    </div>
  );
};

export default FavoriteIcon;
