import React from "react";

import moment from "moment";

import {DateString} from "@globals/types/utilityTypes";

import CustomSelect from "@components/forms/inputs/CustomSelect";

import {CustomSelectProps} from "../CustomSelect/CustomSelect";

interface DateSelectProps extends Omit<CustomSelectProps, "options" | "value"> {
  dates: DateString[];
  closedDates?: DateString[];
  onChange?: (date: DateString) => void;
  value?: DateString;
}

const formatDates = (dates: DateString[], closedDates: DateString[]) => {
  return dates.map((date) => ({
    label: moment(date).format("ddd | MMM D"),
    value: date,
    isDisabled: closedDates.includes(date),
  }));
};

export default function DateSelect({
  name,
  dates,
  closedDates = [],
  label,
  value,
  ...rest
}: DateSelectProps): JSX.Element {
  // add value to the options if it's not present;
  let options = value && !dates.includes(value) ? [value] : [];
  options = options.concat(dates);

  return (
    <CustomSelect
      name={name}
      label={label}
      options={formatDates(options, closedDates)}
      value={value}
      {...rest}
    />
  );
}
