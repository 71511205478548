import React from "react";

import cx from "classnames";

import {useUser} from "@globals/user";

import {AccessRightFlags, CheckAccess} from "@components/CheckAccess";

import styles from "./StaffInfoBanner.scss";

export default function StaffInfoBanner({
  isStaffInfoRelativePosition,
}: {
  isStaffInfoRelativePosition?: boolean;
}): JSX.Element {
  const {user} = useUser();
  return (
    <CheckAccess flag={AccessRightFlags.Authorized}>
      {user?.is_loginasanyone && (
        <div
          className={cx(
            styles.staff,
            isStaffInfoRelativePosition && styles.relativePosition
          )}
        >
          Staff logged in as{" "}
          {user?.first_name
            ? `${user?.first_name} ${user?.last_name}`
            : "anyone"}
        </div>
      )}

      {user?.tenant_override?.id && (
        <div
          className={cx(
            styles.staff,
            isStaffInfoRelativePosition && styles.relativePosition
          )}
        >
          Staff overriding tenant as {user?.tenant_override.name}{" "}
          {user?.tenant_override.id}. Overriding tenant only works with internal
          tools.
        </div>
      )}
    </CheckAccess>
  );
}
