import {RefObject, useEffect} from "react";

type Event = MouseEvent | TouchEvent;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  additionalRef?: RefObject<T>
): void => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      // some additional element that should not trigger the callback
      const isAdditional =
        additionalRef?.current &&
        additionalRef?.current.contains(event?.target as Node);
      if (!el || el.contains((event?.target as Node) || null) || isAdditional) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
