export interface UserContextType {
  // We defined the user type in `index.d.ts`, but it's
  // a simple object with email, name and password.
  user?: UserType;
  isLoading: boolean;
  isError: boolean;
  error?: any;
  isAuthorized: boolean;
  login: ({email, password}: {email: string; password: string}) => void;
  logout: ({
    redirect,
    globalLogout,
  }?: {
    redirect?: boolean;
    globalLogout?: boolean;
  }) => void;
  googleLogin: ({token}: {token: string}) => void;
  fetchUser: () => void;
  canAccess: (product: string) => boolean;
  isVisible: (product: string) => boolean;
  isLunchVisible: () => boolean;
  isAdmin: (product: string, allowLimitedAdmin?: boolean) => boolean;
  isLunchAdmin: (allowLimitedAdmin?: boolean) => boolean;
  hasPPG: (product: string) => boolean;
  hasSubsidy: (product: string, date: string) => boolean;
  hasPDL: (product: string, locationId: number) => boolean;
  getHomepage: () => string;
  setLocation?: (newLocation: {id: number}) => void;
  isLocationLoading: boolean;
  userHasChangedLocation: boolean;
  hasAccessToMultipleDayParts: boolean;
}

export enum DayPart {
  Lunch = "Lunch",
  Breakfast = "Breakfast",
  Dinner = "Dinner",
}

export type UserType = {
  id: number;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  deliveryLocation: string;
  passwordResetKey: string;
  welcomeMatSlug: string;
  operatorSlug: string;
  is_staff: boolean;
  groups?: string[];
  locations_list: LocationType[];
  subsidized_products: {[key: string]: string[]};
  ppg_products: ProductsType[];
  admin_products: string[];
  is_limited_master_account_holder?: boolean;
  selected_location: SelectedLocationType;
  can_serve: boolean;
  is_loginasanyone: boolean;
  tenant_override: {id?: number; name?: string};
  favorite_items: Array<{id: number}>;
  company: {id: number; name: string};
  promotional_credits: number;
  has_brand_page_link: boolean;
  extras_access: {
    [key: string]: {
      [DayPart.Lunch]: boolean;
      [DayPart.Breakfast]: boolean;
      [DayPart.Dinner]: boolean;
    };
  };
  became_admin: string;
  first_login: string;
  can_add_special_instructions: boolean; // Deprecated, use location.can_add_special_order_instructions
  uses_menu_date_prediction?: boolean;
  date_joined: string;
  operator: string;
};

export type LocationSimpleType = {
  id: number;
  coordinates_x: number;
  coordinates_y: number;
  company_name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  name: string;
  migrated_from?: number;
};

export type LocationType = LocationSimpleType & {
  products: string[];
  has_racks: boolean;
  menu_days: string[];
  day_part: DayPart;
  hub_large_group_order_volume: number;
  can_add_special_order_instructions: boolean;
  can_add_delivery_instructions: boolean;
  timezone: string;
  needs_utensils: boolean;
  driver_notes: string;
  delivery_preference: DeliveryPreferenceOption;
  building_and_floor: string;
};

export enum DeliveryPreferenceOption {
  Early = "Early",
  Late = "Late",
  None = "None",
}

export type SelectedLocationType = {
  id: number;
  name: string;
  address: string;
  address2: string;
  timezone: string;
  city: string;
  zip: string;
  state: string;
  day_part: string;
};

export type ProductsType = {
  id: number;
  product: ProductType;
};

export type ProductType = {
  id: number;
  name: string;
};

export enum Product {
  Lunch = "Lunch",
}
