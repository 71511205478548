import React, {useCallback} from "react";

import cx from "classnames";

import HideText from "@components/HideText";
import RadioButton from "@components/RadioButton/RadioButton";
import {RadioButtonColor} from "@components/RadioButton/interfaces";

import {InputOption} from "../../interfaces";
import styles from "./RadioButtonsInput.scss";

export enum RadioButtonsAlignment {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
}

export interface RadioButtonsInputProps {
  name: string;
  options: InputOption[];
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  value?: string | number;
  color?: RadioButtonColor;
  alignment?: RadioButtonsAlignment;
  legendText: string;
  buttonClassName?: string;
  fieldsetClassName?: string;
}

const RadioButtonsInput = ({
  onChange,
  options = [],
  value = "",
  disabled = false,
  legendText = "",
  color = RadioButtonColor.Default,
  buttonClassName,
  ...props
}: RadioButtonsInputProps): JSX.Element => {
  const handleChange = useCallback(
    (value: string) => {
      if (typeof onChange === "function") {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <fieldset
      className={cx(
        styles.radioButtonsContainer,
        props.fieldsetClassName,
        props.alignment === RadioButtonsAlignment.Vertical && styles.vertical
      )}
    >
      <legend>
        <HideText>{legendText}</HideText>
      </legend>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          label={option.label}
          value={option.value}
          color={color}
          onChange={handleChange}
          checked={value === option.value}
          disabled={disabled}
          name={props.name}
          className={buttonClassName}
        />
      ))}
    </fieldset>
  );
};

export default RadioButtonsInput;
