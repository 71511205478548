import moment from "moment";

import {DateString} from "@globals/types/utilityTypes";

export function checkValidDateStr(str: string): str is DateString {
  return /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.exec(str) !== null;
}

/*
 ** toDateString() function is the factory for DateString objects.
 ** You can pass in a Date, a Moment (from Moment.js), or a string in the format of YYYY-MM-DD,
 ** and you’ll get back a DateString.
 */

export default function toDateString(
  date: Date | moment.Moment | string
): DateString {
  if (typeof date === "string") {
    if (checkValidDateStr(date)) {
      return date;
    } else {
      throw new Error(`Invalid date string: ${date}`);
    }
  } else {
    const dateString = moment(date).format("YYYY-MM-DD");
    if (checkValidDateStr(dateString)) {
      return dateString;
    }
  }
  throw new Error(`Shouldn't get here (invalid toDateStr provided): ${date}`);
}
